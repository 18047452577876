import CardContainer from 'components/UI/CardContainer/CardContainer'

import classes from "./FormContainer.module.scss";

import TextField from "../TextField/TextField";

type Props = {
  title: string;
  color: "primary" | "secondary" | "accent1" | "accent2";
  children: JSX.Element;
};

export default function FormContainer({ children, title, color }: Props) {
  return (
    <div className={classes.mainContainer}>
      <TextField
        className={classes.title}
        type="subtitle"
        size="small"
        weight="black"
        color={color}
      >
        {title}
      </TextField>

      <div className={classes.formDataContainer}>
        {children}
      </div>
    </div>
  );
}
