import { IUserProfileLocalRepository, CustomError, errorCodeEnum, computerOSEnum, UserProfile, ProfileInfo, FoodPreferences, shirtSizeEnum } from '../../index'

const userProfileKey = 'userProfile'

export class UserProfileLocalRepositoryImp implements IUserProfileLocalRepository {
    existsUserProfile(): boolean | CustomError {
        return Boolean(localStorage.getItem(userProfileKey))
    }

    getUserProfile(): UserProfile | CustomError {
        const userProfile = localStorage.getItem(userProfileKey);

        if(userProfile) {
            return UserProfile.fromJSONObject(JSON.parse(userProfile))
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    saveUserProfile(userProfile: UserProfile): void | CustomError {
        localStorage.setItem(userProfileKey, JSON.stringify(userProfile))
    }

    newUserProfile(username: string, email: string): UserProfile | CustomError {
        const newUserProfile = new UserProfile(username, email);
        localStorage.setItem(userProfileKey, JSON.stringify(newUserProfile));

        return newUserProfile;
    }

    setProfileImage(profileImage: string): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.setProfileImg(profileImage);
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    setProfileInfo(profileInfo: ProfileInfo): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.setProfileInfo(profileInfo);
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    setFoodPreferences(foodPreferences: FoodPreferences): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.setFoodPreferences(foodPreferences);
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    setShirtSize(shirtSize: shirtSizeEnum): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.setShirtSize(shirtSize);
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    setComputerOS(computerOS: computerOSEnum): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.setComputerOS(computerOS);
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    setAllAboutYouCompleted(): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.setAllAboutYouCompleted();
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    setMeetTheTeamCompleted(): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.setMeetTheTeamCompleted();
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    setAboutHattrickCompleted(): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.setAboutHattrickCompleted();
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    setBenefitsCompleted(): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.setBenefitsCompleted();
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    setToDoListCompleted(): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.setToDoListCompleted();
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    setMeetTheTeamPercentage(percentage: number): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.setMeetTheTeamPercentage(percentage);
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    setBenefitsPercentage(percentage: number): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.setBenefitsPercentage(percentage);
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    setAboutHattrickPercentage(percentage: number): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.setAboutHattrickPercentage(percentage);
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    setNumberOfTasks(number: number): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.setNumberOfTasks(number);
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    addCompletedTask(taskId: string): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.addCompletedTask(taskId)
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    removeCompletedTask(taskId: string): UserProfile | CustomError {
        const currentUserProfile = localStorage.getItem(userProfileKey);

        if(currentUserProfile) {
            const userProfile = UserProfile.fromJSONObject(JSON.parse(currentUserProfile));
            userProfile.removeCompletedTask(taskId)
            localStorage.setItem(userProfileKey, JSON.stringify(userProfile));

            return userProfile;
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'UserProfile not found')
        }
    }

    deleteUserProfile(): void | CustomError {
        localStorage.removeItem(userProfileKey)
    }
}