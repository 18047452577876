import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { localUserProfileInteractor, remoteHattrickInfoInteractor, localAuthInteractor } from "di";
import { CustomError, UserProfile, errorCodeEnum } from "core";

export interface ViewInterface {
  isLoading: boolean;
  isError: boolean;
  error: CustomError | null;
  data:
    | {
        firstName: string;
        teamNumber: number;
        teamImages: string[];
      }
    | undefined;
  onSaveProfileImage: (image: string) => void;
  // onContinue: () => void;
}

type InitialData = {
  firstName: string;
  teamNumber: number;
  teamImages: string[];
};

const fetchInitialData = async (): Promise<InitialData> => {
  try {
    const [teamImages, teamNumber, {username}] = await Promise.all([
      remoteHattrickInfoInteractor.getTeamImages(),
      remoteHattrickInfoInteractor.getTeamNumber(),
      localUserProfileInteractor.getUserProfile(),
    ]) as [string[], number, UserProfile];

    return {
      firstName: username.split(" ")[0],
      teamNumber,
      teamImages,
    };
  } catch (error) {
    throw error;
  }
};

export default function WelcomeViewModel(): ViewInterface {
  const { data, isError, error, isLoading } = useQuery<
    InitialData,
    CustomError
  >(["initialData"], fetchInitialData);

  const navigate = useNavigate();

  useEffect(() => {
    if (error?.code === errorCodeEnum.SERVER_ERROR) {
      localAuthInteractor.deleteToken();
      localUserProfileInteractor.deleteUserProfile();
      alert("Es necesario voler a autenticarse");
      navigate("/");
    }
  }, [error]);

  const saveImageHandler = (image: string) => {
    localUserProfileInteractor.setProfileImage(image);
    setTimeout(() => {
      navigate("/home");
    }, 3000);
  };

  return {
    isLoading,
    isError,
    error,
    data: data ?? undefined,
    onSaveProfileImage: saveImageHandler,
  };
}
