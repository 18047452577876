import classes from "./BackArrow.module.scss";
import TextField from "../TextField/TextField";

import { ReactComponent as BackArrowGray } from "./back-arrow-gray.svg";
import { ReactComponent as BackArrowWhite } from "./back-arrow-white.svg";

type Props = {
  onClick?: () => void;
  color?:
    | "primary"
    | "secondary"
    | "accent1"
    | "accent2"
    | "white"
    | "black"
    | "gray";
};

export default function BackArrow({ onClick, color }: Props) {
  color = color || "gray";
  return (
    <div
      onClick={() => (onClick ? onClick() : null)}
      className={classes.mainContainer}
    >
      {color === "white" ? (
        <BackArrowWhite className={classes.backArrow} />
      ) : (
        <BackArrowGray className={classes.backArrow} />
      )}
      <TextField className={classes.text} color={color} weight="black" type="paragraph" size="small">
        Volver al inicio
      </TextField>
    </div>
  );
}
