import { UserProfile } from "core";
import type { ButtonSectionType } from "components/FinishSection/FinishSection";

export default function mapUserProfileToUncompletedSections(
  userProfile: UserProfile
): ButtonSectionType[] {
  const sections: ButtonSectionType[] = [];

  if (userProfile.allAboutYouPercentage < 100)
    sections.push({
      name: "All About You",
      path: "/all-about-you",
    });
  if (userProfile.aboutHattrickPercentage < 100)
    sections.push({
      name: "About Hattrick",
      path: "/about-hattrick",
    });
  if (userProfile.meetTheTeamPercentage < 100)
    sections.push({
      name: "Meet the team",
      path: "/meet-the-team",
    });
  if (userProfile.benefitsPercentage < 100)
    sections.push({
      name: "Benefits",
      path: "/benefits",
    });
  if (userProfile.toDoListPercentage < 100)
    sections.push({
      name: "To Do List",
      path: "/to-do-list",
    });

  return sections;
}
