import { JSXElementConstructor } from "react";
import { BenefitCard } from "./index";
import { Benefit } from 'core'

type Props = {
  benefitCard1: Benefit;
  benefitCard2?: Benefit;
  active: boolean;
};

export default function TwoCardsContainer({ benefitCard1, benefitCard2, active}: Props) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        gap: "15px",
      }}
    >
      <BenefitCard active={active} benefit={benefitCard1} />
      {benefitCard2 && <BenefitCard active={active} benefit={benefitCard2} />}
    </div>
  );
}
