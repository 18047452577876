import { TextField } from 'components'
import ImagesContainer from './ImagesContainer'

import classes from './Card.module.scss';

type Props = {
    title: string;
    color: 'primary' | 'secondary' | 'accent1' | 'accent2';
    images: [string, string];
    firstParagraph: string;
    secondParagraph?: string;
    textPosition?: 'right' | 'left';
    active?: boolean;
}

export default function Card({title, color, images, firstParagraph, secondParagraph, textPosition, active}: Props) {
    textPosition = textPosition || 'left';
  return (
    <div className={classes.mainContainer}>
        <div className={`${classes.textContainer} ${classes[textPosition]}`}>
            <TextField className={classes.title} type='subtitle' weight='black' color={color}>{title}</TextField>

            <div className={classes.description}>
                <TextField type='paragraph' size='medium' weight='bold' color='gray'>
                    {firstParagraph}
                </TextField>

                {secondParagraph && (
                    <TextField type='paragraph' size='medium' weight='bold' color='gray'>
                        {secondParagraph}
                    </TextField>
                )}
            </div>
        </div>

        <ImagesContainer position={textPosition === 'right' ? 'left' : 'right'} images={images} active={active} />
    </div>
  )
}
