import { IUserProfileLocalRepository, CustomError, UserProfile } from "../../index";

export class SetLocalUserBenefitsCompletedUseCase {
    private localRepository: IUserProfileLocalRepository;

    constructor(localRepository: IUserProfileLocalRepository) {
        this.localRepository = localRepository;
    }

    execute(): UserProfile | CustomError {
        return this.localRepository.setBenefitsCompleted();
    }
}