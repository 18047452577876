import { memo } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { CustomError } from "core";

import classes from "./PageSection.module.scss";
import ProgressBar from "components/UI/ProgressBar/ProgressBar";

import TextField from "components/UI/TextField/TextField";
import BackArrow from "components/UI/BackArrow/BackArrow";
import { BlackLoader } from 'components'

type Props = {
  data:
    | {
        title: string;
        subtitle: string;
        color: "primary" | "secondary" | "accent1" | "accent2";
        completedPercentage: number;
      }
    | undefined;
  isLoading: boolean;
  isError: boolean;
  error: CustomError | null;
  layoutId: string;
  onBack: () => void;
  titleLayoutId?: string;
  progressBarLayoutId?: string;
  hideElements?: boolean;
  children: JSX.Element;
};

export default memo(function PageSection({
  data,
  isLoading,
  isError,
  error,
  layoutId,
  onBack,
  children,
  hideElements,
  titleLayoutId,
  progressBarLayoutId,
}: Props) {
  if (isLoading) {
    return (
      <motion.div
        layoutId={layoutId}
        className={classes.background}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={classes.loaderContainer}>
          <BlackLoader/>
        </div>
      </motion.div>
    );
  }

  if (isError) {
    return (
      <motion.div
        layoutId={layoutId}
        className={classes.background}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1>Error: {error?.description}</h1>
      </motion.div>
    );
  }

  return (
    <motion.div className={classes.background} layoutId={layoutId}>
      <div className={classes.mainContainer}>
        {!hideElements && (
          <div className={classes.topContainer}>
            <div className={classes.textContainer}>
              <motion.div layoutId={titleLayoutId}>
                <TextField
                  color={data!.color}
                  type="subtitle"
                  size="large"
                  weight="black"
                  >
                  {data!.title}
                </TextField>
              </motion.div>
              <TextField
                className={classes.subtitle}
                color="gray"
                type="paragraph"
                size="medium"
                weight="bold"
              >
                {data!.subtitle}
              </TextField>
            </div>

            <motion.div layoutId={progressBarLayoutId} className={classes.progressBarContainer}>
              <ProgressBar
                progress={data!.completedPercentage}
                color={data!.color}
              />
            </motion.div>
          </div>
        )}

        {children}

        <div className={classes.bottomContainer}>
          <BackArrow onClick={onBack} />
        </div>
      </div>
    </motion.div>
  );
});
