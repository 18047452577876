import { IAuthLocalDataRepository, CustomError, TokenType } from '../../index'

export class DeleteTokenUseCase {
    private localAuthRepository: IAuthLocalDataRepository;

    constructor(localAuthRepository: IAuthLocalDataRepository) {
        this.localAuthRepository = localAuthRepository
    }

    execute(): void | CustomError {
        return this.localAuthRepository.deleteToken();
    }
}