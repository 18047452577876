import { useState, memo, useCallback } from "react";
import { shirtSizeEnum } from "core";
import { motion } from "framer-motion";

import sizesTable from "./sizes-table.svg";

import classes from "./Form3.module.scss";

import TextField from "components/UI/TextField/TextField";
import FormContainer from "components/UI/FormContainer/FormContainer";
import Button from "components/UI/Buttons/ButtonSelector";

const availableShirtSizes = [
  shirtSizeEnum.S,
  shirtSizeEnum.M,
  shirtSizeEnum.L,
  shirtSizeEnum.XL,
  shirtSizeEnum.XXL,
];

type Props = {
  title: string;
  color: "primary" | "secondary" | "accent1" | "accent2";
  onChangeForm: (shirtEnum: shirtSizeEnum) => void;
  formValues: shirtSizeEnum;
  shirtImages: {
    first: string;
    second: string;
  };
  disabled?: boolean;
};

export default memo(function Form3({
  title,
  color,
  onChangeForm,
  formValues,
  shirtImages,
  disabled
}: Props) {
  const shirtSize: shirtSizeEnum =
    shirtSizeEnum[formValues as "S" | "M" | "L" | "XL" | "XXL"];

  const selectShirtSizeHandler = useCallback((size: string) => {
    const shirtSize = size as "S" | "M" | "L" | "XL" | "XXL";
    onChangeForm(shirtSizeEnum[shirtSize]);
  }, []);

  return (
    <FormContainer title={title} color={color}>
      <div className={classes.mainContainer}>
        <div className={classes.subtitleContainer}>
          <TextField type="paragraph" size="small" color="gray" weight="bold">
            En este equipo todos nos ponemos la 10!
          </TextField>
          <TextField type="paragraph" size="small" color="gray" weight="bold">
            Aunque algunos no sepamos nada de fútbol, todos sabemos acerca de
            jugarsela por el team.
          </TextField>
        </div>

        <div className={classes.buttons}>
          {availableShirtSizes.map((size) => (
            <motion.div
              whileTap={disabled ? undefined : { scale: 0.9 }}
              whileHover={disabled ? undefined : { scale: 1.1 }}
              key={size}
            >
              <Button
                onClick={selectShirtSizeHandler}
                color="violet"
                selected={size === shirtSize}
                disabled={disabled}
              >
                {size}
              </Button>
            </motion.div>
          ))}
        </div>

        <div className={classes.sizeTable}>
          <TextField
            className={classes.title}
            type="paragraph"
            size="small"
            color="gray"
            weight="bold"
          >
            Tabla de medidas
          </TextField>

          <img src={sizesTable} alt="sizes-table" />
        </div>

        <div className={classes.imagesContainer}>
          <div className={classes.shirt1}>
            <img src={shirtImages.first} alt="shirt1" />
          </div>

          <div className={classes.shirt2}>
            <img src={shirtImages.second} alt="shirt2" />
          </div>
        </div>
        
      </div>
    </FormContainer>
  );
});
