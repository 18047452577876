import TextField from "components/UI/TextField/TextField";
import classes from "./LoginTitle.module.scss";

export default function LoginTitleView() {
  return (
    <>
      <p className={classes.title}>
        Bienvenid@ al equipo
        <span className={classes.titleEndCharacter}>!</span>
      </p>
      {/* <TextField type="title" size="large" color="black" weight="black">
      </TextField>
      <TextField type="span" color="secondary">
      </TextField> */}
      <TextField
        className={classes.subtitle}
        type="paragraph"
        size="large"
        color="gray"
        weight="bold"
      >
        Conocé todo lo que te espera en Hattrick-it
      </TextField>
    </>
  );
}
