import classes from './TeamUserImage.module.scss'

type Props = {
    imageSrc: string;
    active?: boolean;
    onClick?: () => void;
}

export default function TeamUserImage({imageSrc, active, onClick}: Props) {
  return (
    <div onClick={onClick} className={`${classes.mainContainer} ${active ? classes.active : ''}`}>
      <img
        src={imageSrc}
      />
    </div>
  )
}
