import { IRemoteRepository, DataResponse , Benefit } from '../../index'

export class GetComputerImagesUseCase {
    readonly remoteRepository: IRemoteRepository;

    constructor(remoteRepository: IRemoteRepository) {
        this.remoteRepository = remoteRepository;
    }

    async execute(): Promise<DataResponse<{mac: string, windows: string}>> {
        return await this.remoteRepository.fetchComputerImages();
    }
}