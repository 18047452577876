import { useState } from "react";
import { default as useViewModel, ViewInterface } from "./ToDoListViewModel";
import { PageSection, FinishSection } from "components";
import { TasksContainer } from "./components";

import classes from "./ToDoListView.module.scss";

import { Rectangle, Circle } from "components/UI";

export default function ToDoListView() {
  const [showFinishSection, setShowFinishSection] = useState(false);

  const {
    data,
    title,
    subtitle,
    layoutId,
    titleLayoutId,
    progressBarLayoutId,
    color,
    isLoading,
    isError,
    error,
    onBackHome,
    onAddCompleteTask,
    onRemoveCompleteTask,
    onFinish,
  }: ViewInterface = useViewModel();

  const finishSectionHandler = () => {
    onFinish();
    setShowFinishSection(true);
  };

  return (
    <PageSection
      data={{
        title: title,
        subtitle: subtitle,
        color: color,
        completedPercentage: data?.completedPercentage || 0,
      }}
      layoutId={layoutId}
      titleLayoutId={titleLayoutId}
      progressBarLayoutId={progressBarLayoutId}
      isLoading={isLoading}
      isError={isError}
      error={error}
      onBack={onBackHome}
      hideElements={showFinishSection}
    >
      <>
        {showFinishSection ? (
          <FinishSection
            title={title}
            color={color}
            sections={data!.uncompletedSections}
            titleLayoutId={titleLayoutId}
            progressBarLayoutId={progressBarLayoutId}
          />
        ) : (
          data && (
            <div className={classes.taskContainer}>
              <TasksContainer
                onAddCompleteTask={onAddCompleteTask}
                onRemoveCompleteTask={onRemoveCompleteTask}
                tasks={data.tasks}
                userCompletedTasks={data.userCompletedTaskIds}
                imageSrc={data.taskSectionImage}
                disabled={data?.completed}
                disabledFinish={data?.completedPercentage < 99}
                showFinish={!data?.completed}
                onFinish={finishSectionHandler}
              />
            </div>
          )
        )}

        <div style={{ zIndex: -1 }}>
          <Circle bottomStart color="violet" light xPos="-10%" yPos="10%" />
          <Rectangle bottomStart color="green" light xPos="30%" yPos="40%" />
        </div>
      </>
    </PageSection>
  );
}
