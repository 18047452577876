import { IUserProfileLocalRepository, CustomError, UserProfile } from "../../index";

export class SetLocalUserAllAboutYouCompletedUseCase {
    private localRepository: IUserProfileLocalRepository;

    constructor(localRepository: IUserProfileLocalRepository) {
        this.localRepository = localRepository;
    }

    execute(): UserProfile | CustomError {
        return this.localRepository.setAllAboutYouCompleted();
    }
}