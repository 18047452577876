import {
  IAuthLocalDataRepository,
  CustomError,
  GetTokenUseCase,
  SetTokenUseCase,
  IsLoggedInUseCase,
  DeleteTokenUseCase,
  TokenType,
} from "../index";

export class LocalAuthInteractor {
  private localAuthRepository: IAuthLocalDataRepository;

  private getTokenUseCase: GetTokenUseCase;
  private setTokenUseCase: SetTokenUseCase;
  private isLoggedInUseCase: IsLoggedInUseCase;
  private deleteTokenUseCase: DeleteTokenUseCase;

  constructor(localAuthRepository: IAuthLocalDataRepository) {
    this.localAuthRepository = localAuthRepository;

    this.getTokenUseCase = new GetTokenUseCase(this.localAuthRepository);
    this.setTokenUseCase = new SetTokenUseCase(this.localAuthRepository);
    this.isLoggedInUseCase = new IsLoggedInUseCase(this.localAuthRepository);
    this.deleteTokenUseCase = new DeleteTokenUseCase(this.localAuthRepository);
  }

  getToken(): TokenType | CustomError {
    return this.getTokenUseCase.execute();
  }

  setToken(token: TokenType): void | CustomError {
    return this.setTokenUseCase.execute(token);
  }

  isLoggedIn(): boolean | CustomError {
    return this.isLoggedInUseCase.execute();
  }

  deleteToken(): void | CustomError {
    return this.deleteTokenUseCase.execute();
  }
}
