import { UserAuth, IRemoteRepository, DataResponse } from '../../index'

export class LoginUseCase {
    readonly remoteRepository: IRemoteRepository;

    constructor(remoteRepository: IRemoteRepository) {
        this.remoteRepository = remoteRepository
    }

    async execute(email: string, password: string): Promise<DataResponse<UserAuth>> {
        return this.remoteRepository.login(email, password);
    }
}