import { memo } from 'react'
import { Routes, Route } from 'react-router-dom'

import classes from './HomeView.module.scss'

import Navbar from 'components/Navbar/NavbarView'
import AllAboutYou from 'pages/AllAboutYou/AllAboutYouView'
import AboutHattrick from 'pages/AboutHattrick/AboutHattrickView'
import MeetTheTeam from 'pages/MeetTheTeam/MeetTheTeamView'
import Benefits from 'pages/Benefits/BenefitsView'
import ToDoList from 'pages/ToDoList/ToDoListView'

import CardsContainer from './components/Cards/CardsContainer'

function HomeView() {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.navbarContainer}>
        <Navbar/>
      </div>
      <Routes>
        <Route path='/' element={<CardsContainer/>}/>
        <Route path='/all-about-you' element={<AllAboutYou/>} />
        <Route path='/about-hattrick' element={<AboutHattrick/>} />
        <Route path='/meet-the-team' element={<MeetTheTeam/>} />
        <Route path='/benefits' element={<Benefits/>} />
        <Route path='/to-do-list' element={<ToDoList/>} />
      </Routes>
    </div>
  )
}

export default memo(HomeView)
