import { IUserProfileLocalRepository, CustomError } from "../../index";

export class ExistsLocalUserProfileUseCase {
    private localRepository: IUserProfileLocalRepository;

    constructor(localRepository: IUserProfileLocalRepository) {
        this.localRepository = localRepository;
    }

    execute(): boolean | CustomError {
        return this.localRepository.existsUserProfile();
    }
}