import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import classes from "./DropDownMenu.module.scss";

type MenuItem = {
  title: string;
  onClick: () => void;
};

type Props = {
  elements: MenuItem[];
};

const DropDownMenu = ({ elements }: Props) => {
  const [open, setOpen] = useState(false);

  const location = useLocation();
  useEffect(() => {
    setOpen(false);
  }, [location])

  useEffect(() => {
    document.addEventListener("mousedown", clickHandler);

    return () => {
      document.removeEventListener("mousedown", clickHandler);
    };
  }, []);

  const openMenuHandler = () => {
    setOpen(true);
  };

  const clickHandler = () => {
    setOpen(false);
  };

  return (
    <div className={classes.mainContainer}>
      <div onClick={openMenuHandler} className={classes.iconContainer}>
        <div />
        <div />
        <div />
      </div>

      <div className={`${classes.listContainer} ${open ? classes.open : ""}`}>
        <div className={classes.closeIconContainer}>
          <div className={classes.first} />
          <div className={classes.second} />
        </div>
        <ul>
          {elements.map((e, i) => (
            <li onClick={e.onClick} key={i}>{e.title}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropDownMenu;
