import { UserProfile } from "core";
import { localUserProfileInteractor, localAuthInteractor } from "di";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthStore } from "context/authContext";

export interface ViewInterface {
  user: UserProfile | undefined;
  onLogout: () => void;
}

export default function NavbarViewModel(): ViewInterface {
  const {setIsLoggedIn} = useAuthStore();
  const [user, setUser] = useState<UserProfile | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      setUser(localUserProfileInteractor.getUserProfile() as UserProfile);
    } catch (error) {
      throw error;
    }
  }, []);

  const logoutHandler = () => {
    try {
      setIsLoggedIn(false)
      localAuthInteractor.deleteToken();
      localUserProfileInteractor.deleteUserProfile();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    user: user ?? undefined,
    onLogout: logoutHandler,
  };
}
