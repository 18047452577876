import { useState, useEffect } from "react";
import { motion, useCycle } from "framer-motion";

import classes from "./WelcomeView.module.scss";

import { default as useViewModel, ViewInterface } from "./WelcomeViewModel";

import TextField from "components/UI/TextField/TextField";
import image from "assets/Gaetano.jpg";
import IconImage from "components/UI/IconImage/IconImage";
import Logo from "components/UI/Logo/Logo";
import UploadImage from "components/UI/UploadImage/UploadImage";
import { WhiteLoader } from 'components'

const imagesVariants = {
  hidden: { y: "100vh" },
  visible: { y: 0, transition: { duration: 2, type: "spring" } },
};

const titleVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 2 },
  },
  raise: {
    opacity: 1,
    y: "-20vh",
    scale: 0.65,
    transition: { duration: 1, type: "spring" },
  },
};

const subtitleVariants = {
  hidden: {
    opacity: 0,
    y: "-100%",
    scale: 0,
  },

  visible: {
    opacity: 1,
    y: "calc(-20vh - 15px)",
    scale: 1,
    transition: { duration: 1, type: "spring" },
  },
};

const uploadButtonVariants = {
  hidden: { y: "100vh" },
  visible: {
    y: 0,
    transition: {
      duration: 1.5,
      type: "spring",
    },
  },
};

const teamImagesPosition = [
  {
    image: image,
    bottom: "50%",
    left: "5%",
  },
  {
    image: image,
    bottom: "42%",
    left: "18%",
  },
  {
    image: image,
    bottom: "27%",
    left: "10%",
  },
  {
    image: image,
    bottom: "25px",
    left: "2%",
  },
  {
    image: image,
    bottom: "20px",
    left: "18%",
  },
  {
    image: image,
    bottom: "15%",
    left: "27%",
  },
  {
    image: image,
    bottom: "3%",
    left: "40%",
  }, //now from the right
  {
    image: image,
    bottom: "58%",
    right: "8%",
  },
  {
    image: image,
    bottom: "34%",
    right: "15%",
  },
  {
    image: image,
    bottom: "32%",
    right: "2%",
  },
  {
    image: image,
    bottom: "6%",
    right: "5%",
  },
  {
    image: image,
    bottom: "5%",
    right: "18%",
  },
  {
    image: image,
    bottom: "25%",
    right: "27%",
  },
  {
    image: image,
    bottom: "7%",
    right: "37%",
  },
];

shuffleArray(teamImagesPosition);

function shuffleArray(array: object[]) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export default function Welcome() {
  const { isLoading, isError, error, data, onSaveProfileImage }: ViewInterface =
    useViewModel();

  const [animation, setAnimation] = useState(0);
  const [titleAnimation, cycleTitleAnimation] = useCycle("visible", "raise");

  const nextAnimation = () => {
    setAnimation((prev) => prev + 1);
  };

  if (isError) {
    return (
      <div>
        {error?.description || "An error occurred, please try again later"}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        className={classes.mainLoadingContainer}
      >
        <Logo absolutePos color="white" />
        <div className={classes.spinnerContainer}>
          <WhiteLoader/>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.mainContainer}>
      <Logo absolutePos color="white" />
      <>
        <div className={classes.titleContainer}>
          <motion.div
            variants={titleVariants}
            initial="hidden"
            animate={titleAnimation}
            onAnimationComplete={() => {
              if (titleAnimation === "raise") nextAnimation();

              cycleTitleAnimation(1);
            }}
          >
            <TextField
              type="title"
              size="medium"
              align="center"
              color="white"
              weight="black"
            >
              {`Bienvenid@ ${data!.firstName}`}
            </TextField>
          </motion.div>

          <motion.div
            variants={subtitleVariants}
            initial="hidden"
            animate={animation >= 1 ? "visible" : ""}
          >
            <TextField
              type="subtitle"
              size="medium"
              align="center"
              color="white"
              weight="bold"
            >{`Contigo ya somos ${data!.teamNumber + 1} en el equipo`}</TextField>
          </motion.div>
        </div>

        <motion.div
          initial="hidden"
          animate={animation >= 1 ? "visible" : ""}
          transition={{ staggerChildren: 0.2 }}
          onAnimationComplete={nextAnimation}
        >
          {teamImagesPosition?.map((member, i) => {
            return (
              <motion.div
                key={i}
                style={{
                  bottom: member.bottom,
                  left: member.left,
                  right: member.right,
                }}
                className={classes.iconImageContainer}
                variants={imagesVariants}
              >
                <IconImage
                  image={
                    data!.teamImages[
                      i % data!.teamImages.length
                    ] /* complete all the image containers */
                  }
                  size={i % 2 == 0 ? "large" : "medium"}
                  color="secondary"
                />
              </motion.div>
            );
          })}
        </motion.div>

        <motion.div
          variants={uploadButtonVariants}
          initial="hidden"
          animate={animation >= 2 ? "visible" : ""}
          className={classes.uploadImageContainer}
        >
          <UploadImage onEnd={onSaveProfileImage} />
        </motion.div>

        <motion.div
          className={classes.roundedCover}
          initial={{ scale: 0 }}
          exit={{ scale: 40, transition: { duration: 0.5 } }}
        ></motion.div>
      </>
    </div>
  );
}
