import { useState, memo } from "react";
import { ProfileInfo } from "core";
import classes from "./Form1.module.scss";
import TextField from "components/UI/TextField/TextField";
import InputForm from "components/UI/InputForm/InputForm";

import FormContainer from "components/UI/FormContainer/FormContainer";
import BornDateInput from './BornDateInput'

type Props = {
  title: string;
  color: "primary" | "secondary" | "accent1" | "accent2";
  onChangeForm: (field: string, value: string) => void;
  formValues: ProfileInfo;
  disabled?: boolean;
}

export default memo(function Form1({title, color, onChangeForm, formValues, disabled}: Props) {
  const profileValues: ProfileInfo = formValues;

  const changeUserDataHandler = (field: string, value: string) => {
    onChangeForm(field, value);
  };

  return (
    <div className={classes.mainContainer}>
      <FormContainer title={title} color={color}>
        <div className={classes.formContainer}>
          <div className={classes.sideContainer}>
            <div className={classes.surnameContainer}>
              <TextField
                className={classes.title}
                color="black"
                weight="bold"
                type="paragraph"
                size="small"
              >
                Como te gusta que te digan?
              </TextField>

              <InputForm
                color="secondary"
                name="nickName"
                size="large"
                value={profileValues.nickName || ''}
                id="nickName"
                onChange={changeUserDataHandler}
                disabled={disabled}
              />
            </div>

            <div className={classes.birthdayContainer}>
              <TextField
                className={classes.title}
                color="black"
                weight="bold"
                type="paragraph"
                size="small"
              >
                Tu fecha de cumpleaños
              </TextField>

              <BornDateInput disabled={disabled} field='bornDate' onChangeDate={changeUserDataHandler} dateValue={profileValues.bornDate} />
            </div>

            <div className={classes.numberContainer}>
              <TextField
                className={classes.title}
                color="black"
                weight="bold"
                type="paragraph"
                size="small"
              >
                Tu teléfono de contacto
              </TextField>

              <InputForm
                color="secondary"
                name="contactNumber"
                size="large"
                type='number'
                value={profileValues.contactNumber || ''}
                id="contactNumber"
                onChange={changeUserDataHandler}
                disabled={disabled}
              />
            </div>
          </div>

          <div className={classes.sideContainer}>
            <div className={classes.about}>
              <TextField
                className={classes.title}
                color="black"
                weight="bold"
                type="paragraph"
                size="small"
              >
                Algo que quieras contarnos sobre vos
              </TextField>

              <InputForm
                color="secondary"
                name="aboutMe"
                size="textarea"
                weight="bold"
                value={profileValues.aboutMe || ''}
                id="aboutMe"
                onChange={changeUserDataHandler}
                disabled={disabled}
              />
            </div>

            <div className={classes.address}>
              <TextField
                className={classes.title}
                color="black"
                weight="bold"
                type="paragraph"
                size="small"
              >
                Tu dirección
              </TextField>

              <InputForm
                color="secondary"
                name="address"
                size="large"
                value={profileValues.address || ''}
                id="address"
                onChange={changeUserDataHandler}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </FormContainer>
    </div>
  );
})
