import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "App";
import MobileModal from "pages/MobileModal/MobileModalView";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "context/authContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const queryClient = new QueryClient();

const screenWidth = window.innerWidth;

root.render(
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        {screenWidth >= 1100 ? <App /> : <MobileModal />}
      </BrowserRouter>
    </QueryClientProvider>
  </AuthProvider>
);
