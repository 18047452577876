export class TeamUser {
  readonly name: string;
  readonly position: string;
  readonly description: string;
  readonly image: string;
  readonly linkedinURL?: string;
  readonly instagramURL?: string;
  readonly twitterURL?: string;

  constructor(name: string, position: string, description: string, image: string, linkedinURL?: string, instagramURL?: string, twitterURL?: string) {
    this.name = name;
    this.position = position;
    this.description = description;
    this.image = image;
    this.linkedinURL = linkedinURL;
    this.instagramURL = instagramURL;
    this.twitterURL = twitterURL;
  }
}