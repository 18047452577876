import { TextField } from 'components'
import { Task as TaskType } from "core";
import classes from "./Task.module.scss";
import { CheckBox } from "./index";

type Props = {
  onComplete: (taskId: string) => void;
  onUncomplete: (taskId: string) => void;
  task: TaskType;
  done: boolean;
  disabled?: boolean;
};

export default function Task({ onComplete, onUncomplete, task, done, disabled }: Props) {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.checkboxContainer}>
        <CheckBox
          onClick={() => !done ? onComplete(task.id) : onUncomplete(task.id)}
          done={done}
          disabled={disabled}
        />
      </div>

      <div className={classes.textContainer}>
        <TextField color='gray' weight='black' type='paragraph' size='large'>{task.title}</TextField>

        <TextField color='gray' weight='bold' type='paragraph'>{task.description}</TextField>
      </div>
    </div>
  );
}
