import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { AllAboutYou, AboutHattrick, Benefits, MeetTheTeam, ToDoList } from './index'

import { motion } from "framer-motion";

import classes from "./CardsContainer.module.scss";

type CardInfo = {
  component: React.ReactNode;
  id: string;
  path: string;
};

const topCards = [
  {
    component: (
      <AllAboutYou className={classes.sectionContainer} />
    ),
    id: "all-about-you",
    path: "/home/all-about-you",
  },
  {
    component: (
      <AboutHattrick className={classes.sectionContainer} />
    ),
    id: "about-hattrick",
    path: "/home/about-hattrick",
  },
];

const bottomCards = [
  {
    component: (
      <MeetTheTeam className={classes.sectionContainer} />
    ),
    id: "meet-the-team",
    path: "/home/meet-the-team",
  },
  {
    component: (
      <Benefits className={classes.sectionContainer} />
    ),
    id: "benefits",
    path: "/home/benefits",
  },
  {
    component: (
      <ToDoList className={classes.sectionContainer} />
    ),
    id: "to-do-list",
    path: "/home/to-do-list",
  },
];

export default function CardsContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const prevLinkProps: any = location.state;
  const elevatedCard = prevLinkProps?.elevatedCard;

  return (
    <div className={classes.mainContainer}>

      <div className={classes.topSectionContainer}>
        {topCards.map((card, i) => (
          <motion.div
            key={i}
            style={{ zIndex: elevatedCard === card.id ? 1000 : undefined, height:'100%', width: '100%'}}
            onClick={() => navigate(card.path)}
            layoutId={card.id}
          >
            {card.component}
          </motion.div>
        ))}
      </div>

      <div className={classes.bottomSectionContainer}>
        {bottomCards.map((card, i) => (
          <motion.div
          key={i}
          style={{ zIndex: elevatedCard === card.id ? 1000 : undefined, height: "100%", width: '100%'}}
          onClick={() => navigate(card.path)}
          layoutId={card.id}
          >
            {card.component}
          </motion.div>
        ))}
      </div>

    </div>
  );
}
