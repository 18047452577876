import { IRemoteRepository, DataResponse } from '../../index'

export class GetTeamImagesUseCase {
    readonly remoteRepository: IRemoteRepository;

    constructor(remoteRepository: IRemoteRepository) {
        this.remoteRepository = remoteRepository;
    }

    async execute(quantity: number): Promise<DataResponse<string[]>> {
        return await this.remoteRepository.fetchUserImages(quantity);
    }
}