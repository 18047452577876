import { IUserProfileLocalRepository, CustomError, UserProfile, shirtSizeEnum } from "../../index";

export class SetLocalUserShirtSizeUseCase {
    private localRepository: IUserProfileLocalRepository;

    constructor(localRepository: IUserProfileLocalRepository) {
        this.localRepository = localRepository;
    }

    execute(shirtSize: shirtSizeEnum): UserProfile | CustomError {
        return this.localRepository.setShirtSize(shirtSize);
    }
}