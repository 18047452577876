import { motion } from "framer-motion";
import { Benefit } from "core";
import { TextField } from "components/UI";

import classes from "./BenefitCard.module.scss";

type Props = {
  benefit: Benefit;
  active: boolean;
};

export default function BenefitCard({ benefit, active }: Props) {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.textContainer}>
        <div className={classes.titleContainer}>
          <TextField className={classes.title} type="subtitle" weight="black" color="accent2">
            {benefit.title}
          </TextField>
          {benefit.extraImage && (
            <motion.div 
              initial={{ x: 0, y: 0, scale: 1 }}
              animate={active ? { x: 30, y: -20, scale: 1.5 } : undefined}
              transition={{ delay: 0.3 }}
              className={classes.extraImage}>
              <img src={benefit.extraImage} />
            </motion.div>
          )}
        </div>


        <div className={classes.descriptionContainer}>
          <TextField type="paragraph" color="gray" weight="bold">
            {benefit.description}
          </TextField>
        </div>
      </div>

      <div className={classes.imagesContainer}>
        <motion.div
          initial={{ x: 0, y: 0, scale: 1 }}
          animate={active ? { x: 10, y: 20, scale: 1.3 } : undefined}
          transition={{ delay: 0.3 }}
          className={`${classes.image} ${
            benefit.extraImage ? classes.smaller : ""
          }`}
        >
          <img src={benefit.images[0]} />
        </motion.div>
        <motion.div
          initial={{ x: 0, y: 0, scale: 1 }}
          animate={active ? { x: -10, y: -10, scale: 1.3 } : undefined}
          transition={{ delay: 0.3 }}
          className={`${classes.image} ${
            benefit.extraImage ? classes.smaller : ""
          }`}
        >
          <img src={benefit.images[1]} />
        </motion.div>
      </div>
    </div>
  );
}
