import { motion } from "framer-motion";
import Logo from "components/UI/Logo/Logo";
import classes from "./LoginView.module.scss";

import LoginTitleView from "./components/LoginTitleView";
import LoginFormView from "./components/LoginFormView";

import Circle from "components/UI/Shapes/Circle/Circle";
import Rectangle from "components/UI/Shapes/Rectangle/Rectangle";

import { default as useViewModel, ViewInterface } from "./LoginViewModel";

export default function LoginView() {
  const { isValidUrl, isError, error, onLogin, onResetForm, isLoading }: ViewInterface = useViewModel();

  return (
    <motion.div className={classes.container}>
      <Logo absolutePos color="blue" />

      <div className={classes.loginComponentsContainer}>
        <div className={classes.leftContainer}>
          <Circle xPos="-45%" yPos="65%" color="blue" size='large' />
          <LoginTitleView />
        </div>

        <div className={classes.rightContainer}>
          <LoginFormView disabled={!isValidUrl} isError={isError} error={error} onLogin={onLogin} onReset={onResetForm} onLoading={isLoading}/>
          <Rectangle zIndex={1} yPos="-71%" xPos="6%" color="violet" />
          <Rectangle yPos="-102%" xPos="-20%" color="green" />
        </div>
      </div>
    </motion.div>
  );
}
