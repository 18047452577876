import { useEffect, useState } from "react";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { localUserProfileInteractor } from "di";
import { UserProfile } from "core";

type Props = {
  onClick?: () => void;
  className?: string;
};

export default function ToDoList({ onClick, className }: Props) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        if (localUserProfileInteractor.existsUserProfile()) {
          const userProfile =
            localUserProfileInteractor.getUserProfile() as UserProfile;
          setProgress(userProfile.toDoListPercentage);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <div
      onClick={() => onClick}
      className={className}
      style={{ width: "100%", height: "100%" }}
    >
      <GenericCard
        title="To Do List"
        subtitle="Arrancá con todo! \n
        Dejemos todo listo para tu primer día."
        color="blue"
        progress={progress}
      />
    </div>
  );
}
