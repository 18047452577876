import { IUserProfileLocalRepository, CustomError, UserProfile, computerOSEnum } from "../../index";

export class SetLocalUserComputerOSUseCase {
    private localRepository: IUserProfileLocalRepository;

    constructor(localRepository: IUserProfileLocalRepository) {
        this.localRepository = localRepository;
    }

    execute(computerOS: computerOSEnum): UserProfile | CustomError {
        return this.localRepository.setComputerOS(computerOS);
    }
}