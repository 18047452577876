import { useState, useRef } from "react";

import ReactCrop, { Crop } from "react-image-crop";
import "./ReactCrop.scss";

// import image from "../../images/Gaetano.jpg";
import classes from "./ImageResizer.module.scss";
import Circle from "../Shapes/Circle/Circle";
import Button from '../Buttons/Button'

type Props = {
  onCropImage: (base64Img: string) => void;
  selectedImage: string,
};

export default function ImageResizer({ onCropImage, selectedImage }: Props) {
  const imgRef = useRef<any>();
  const imageRef = imgRef.current;

  const [crop, setCrop] = useState<Crop>({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });

  const getCroppedImg = async (imageRef: any, crop: Crop) => {
    try {
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.naturalWidth / imageRef.width;
      const scaleY = imageRef.naturalHeight / imageRef.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d")!;
      ctx.drawImage(
        imageRef,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg", 1);
      return base64Image;
    } catch (e) {
      console.log(e);
    }
  };

  const confirmResizeImageHandler = async () => {
    const croppedImage = await getCroppedImg(imageRef, crop);
    onCropImage(croppedImage!);
  }

  return (
    <>
      <div className={classes.mainContainer}>
        <ReactCrop
          className={classes.selectedImageContainer}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onDragEnd={confirmResizeImageHandler}
          aspect={1}
          keepSelection={true}
          circularCrop
        >
          <img
            ref={imgRef}
            className={classes.selectedImageContainer}
            src={selectedImage}
          />
        </ReactCrop>
      </div>
    </>
  );
}
