import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion'

import { TextField, SectionButton, ProgressBar } from "components/UI";

import classes from "./FinishSection.module.scss";

import partyIcon from "assets/party-icon.svg";

type Props = {
  title: string;
  color: "primary" | "secondary" | "accent1" | "accent2";
  sections: ButtonSectionType[];
  titleLayoutId?: string;
  progressBarLayoutId?: string;
};

export type ButtonSectionType = {
  name: string;
  path: string;
  color?: "primary" | "secondary" | "accent1" | "accent2";
};

function getSectionsWithColors(sections: ButtonSectionType[]) {
  return sections.map((section) => {
    const returnObject: ButtonSectionType = {
      name: section.name,
      path: section.path,
      color: "primary",
    };

    switch (section.name.toLowerCase()) {
      case "all about you": {
        returnObject.color = "accent1";
        break;
      }

      case "about hattrick": {
        returnObject.color = "secondary";
        break;
      }

      case "benefits": {
        returnObject.color = "accent2";
        break;
      }
    }

    return returnObject;
  });
}

export default function FinishSection({ title, color, sections, titleLayoutId, progressBarLayoutId }: Props) {
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const coloredSections = getSectionsWithColors(sections);

  useEffect(() => {
    if(sections.length === 0){
      setTimeout(() => {
        navigate("/onboarding-completed");
      }, 2000)
    }
  }, [sections])

  useEffect(() => {
    setProgress(100);
  }, [])

  return (
    <div className={classes.mainContainer}>
      <div className={classes.titleContainer}>

        <motion.div layoutId={titleLayoutId}>
          <TextField color={color} type="subtitle" size="large" weight="black">
            {title}
          </TextField>
        </motion.div>

        <motion.div layoutId={progressBarLayoutId} className={classes.progressBarContainer}>
          <ProgressBar color={color} progress={progress} />
        </motion.div>

        <img src={partyIcon} alt="party-icon" />
      </div>

      {coloredSections.length > 0 && 
        <div className={classes.nextSectionContainer}>
          <TextField
            align="center"
            color="gray"
            type="paragraph"
            size="large"
            weight="bold"
          >
            {coloredSections.length > 1
              ? "Elegí con qué sección querés continuar:"
              : "Te queda solo una sección para terminar!"}
          </TextField>

          <div className={classes.buttonContainer}>
            {coloredSections.map((section, i: number) => (
              <SectionButton
                key={i}
                color={section.color!}
                onClick={() => {
                  navigate("/home" + section.path);
                }}
              >
                {section.name}
              </SectionButton>
            ))}
          </div>
        </div>
      }
    </div>
  );
}
