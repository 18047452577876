import {
  IRemoteRepository,
  DataResponse,
  UserAuth,
  UserProfile,
  TeamUser,
  AboutHattrickCard,
  Benefit,
  Task,
  CustomError,
  errorCodeEnum,
} from "core/domain";

import shirt1 from "./images/shirt1.png";
import alfredoImg from "./images/alfredo.jpg";
import baumannImg from "./images/baumann.jpg";
import diegoImg from "./images/diego.jpg";
import estebanImg from "./images/esteban.jpg";
import ferImg from "./images/fer.jpg";
import gaetanoImg from "./images/Gaetano.jpg";
import ineImg from "./images/ine.png";
import joacoImg from "./images/joaco.jpg";
import juanImg from "./images/juan.jpg";
import kariImg from "./images/kari.jpg";
import katImg from "./images/kat.jpg";
import marceImg from "./images/marce.jpg";
import martinImg from "./images/martin.png";
import maugeImg from "./images/mauge.jpg";
import pabloImg from "./images/pablo.png";
import tongaImg from "./images/tonga.png";
import valeImg from "./images/vale.jpg";
import toDoList from "./images/to-do-list.png";
import macbook from "./images/macbook.jpg";
import windows from "./images/windows.jpg";
import slack from "./images/slack.jpg";
import standup from "./images/standup.jpg";
import pbsco from "./images/pbsco.jpg";
import brainco from "./images/brainco.jpg";
import historia1 from "./images/historia1.jpg";
import historia2 from "./images/historia2.jpg";
import pros from "./images/pros.jpg";
import pros2 from "./images/pros2.jpg";
import teclado from "./images/teclado.jpg";
import auriculares from "./images/auriculares.jpg";
import cumple from "./images/cumple.jpg";
import tiempoLibre from "./images/tiempo-libre.jpg";
import gim1 from "./images/gim1.jpg";
import gim2 from "./images/gim2.jpg";
import estudiando from "./images/estudiando.jpg";
import tituloUniversitario from "./images/titulo-universitario.png";
import pingpong from "./images/pingpong.png";
import shirt2 from "./images/shirt2.png";
import trabajoRemoto from "./images/trabajo-remoto.jpg";

export const validUser = {
  username: "Mateo",
  email: "mateo@hattrick-it.com",
  password: "180200",
  token: "testToken1234",
};

export const fakeValidUser = {
  username: "Test",
  email: "test@hattrick-it.com",
  password: "123456",
  token: "testToken1234",
};

const alfredoDesc =
  "Buenas! Me llamo Alfredo! No me gustan mucho los apodos pero te desafío a que me inventes uno vos, quizá me guste  Estoy en la parte de testing dentro del equipo de PBSCO, asi que me vas a ver sacando fotos y probando que todo funcione como corresponde. \nFuera del trabajo me estoy aprendiendo Francés, Canto y Ukelele! 3 de las cosas que más me hacen feliz, las otras 8 son mis hermosos gatitos Soy vegetariano, a veces vegano y mi comida favorita son las hamburguesas de zanahoria con verduras salteadas!Mis amigos siempre me dicen que soy muy creativo porque siempre me las ingenio para llevarlos a lugares diferentes y divertidos";
const ineDesc =
  "Hola! Soy Maria Inés, pero me dicen Ine. Mi rol dentro de Hattrick es como Office Manager, así que si necesitas algo de la oficina, o tenés alguna sugerencia para mejorarla ya sabes con quién hablar!\nMis hobbies son estar con mis amigas y probar vinitos ricos EN COPA.Mi menú favorito: sandwich caliente de la frago o hamburguesas (tengo top 3 de hamburgueserias de uru). Mis amigos dicen de mi que.soy DEMASIADO intensa (debe ser porque tengo luna en Cancer).";
const pabloDesc =
  "Soy Pablo, desarrollador y evangelizador Flutter. Mi pasión es ver series y películas, todas. También soy apasionado de hacer asados! ( y pedir ticholos ). \nSiempre voy a la oficina los días de MILANESAS. Mis amigos dicen que soy fundamentalista de Peñarol ( Peñarol Peñarol ).";
const katDesc =
  "Me llamo Katya, pero me dicen Kat, Kata, Katita. Soy diseñadora, fotógrafa. Vivo con mi novio Agu y mis dos gatos Cabeza y Ñery (la casa es de los gatos, nosotros vivimos de prestados). \nNetflix & chill como estilo de vida, mi plan perfecto de fin de semana es maratonear alguna serie y comer chatarra. Mi top 3 de series favoritas: Mr Robot, Ozark, The Office. Mi comida favorita: Canelones o tallarines (la realidad es que mientras sea vegan me gusta casi cualquier cosa)";
const marceDesc =
  "Mi nombre es Marcelo Seoane, me dicen Marce y trabajo como Android Developer desde enero del 2022. Me gusta mirar y jugar al futbol, jugar al play y ver películas y series. También me gusta ir al gimnasio o crossfit aunque ahora no lo hago mucho. \nEstoy en segundo año de Ingeniería en Computación en la UdelaR. Mi comida favorita son las hamburguesas con papas fritas, más que nada las de Rudy. Mis amigos dicen de mi que se muchos datos random inecesarios";
const maugeDesc =
  "Mi nombre es Maria Eugenia pero me dicen Mauge. Soy de Piscis. Estudie Relaciones Internaciones y me desempeño como Business Developer en Hattrick. Mis hobbies incluyen juntarme con amigos a tomar mate, pasear con mi perra Dalma e ir al gimnasio. \nMi comida favorita es la hamburguesa Butter de HDP. Mis amigos dicen que soy demasiado racional y a la vez muy sensible.";
const diegoDesc =
  "Mi nombre es Diego de Araújo, no tengo apodo. Dentro de Hattrick cumplo el rol de Teach Lead de Android y Flutter, tiro piques de git y soy promotor de la huerta. \nEn mi tiempo libre me gusta ir al gimnasio, jugar al basket, cocinar y leer. Mi comida favorita es la pizza con ananá, y me la banco. Como segunda opción elijo el sushi. Mis amigos dicen de mí que hago la mejor pizza del condado.";
const karinaDesc =
  "Hola soy Karina, pero me dicen Kari (por favor decime Kari porque sino siento que me estan rezongando) Dentro de hattrick mi rol es de people care. Me gusta caminar, conocer lugares y hacer turismo aventura! (ojo porque intento contagiar el espíritu aventurero al equipo! ) Mi comida favorita es el pescadito en casi todas sus formas. (excepto el caviar).\nMis amigos dicen de mi que siempre llego sobre la hora (o tarde) pero siempre con buena onda!";
const baumannDesc =
  "Hola, yo soy Martin Baumann, tengo 26 años y formo parte del equipo de QA, como Tester. En mis ratos libres, me gusta principalmente ir al gimnasio, estudiar psicología (actualmente estoy en 3er año de la carrera), salir con amigos a contar por décima vez las mismas anécdotas de hace 15 años y jugar Counter Strike (dato esencial).\nMi comida favorita es milanesa con puré, indiscutidamente. También soy gran fan del Sushi. Mis amigos dicen de mi que no entienden como puedo hinchar por Boca Jrs, yo tampoco lo entiendo, pero sucedió.";
const joacoDesc =
  "Mi nombre es Joaquín Mendizábal no tengo apodo pero generalmente me dicen Joaco o Mendi, ingresé a Hattrick en enero de 2022 para cubrir el puesto de Android Jr Developer, uno de mis principales hobbies es ir al gimnasio pero también me gusta practicar algún deporte principalmente fútbol, también me gusta ver todo tipo de deportes (futbol, basquet, automovilismo, UFC, etc…), también me gusta mirar películas y soy más de ver videos en YouTube que series.\nSi tuviera que elegir un menú favorito probablemente elegiría Hamburguesa con papas fritas, mis amigos dicen de mi que es imposible que llegue temprano a las reuniones/eventos.";
const ferDesc =
  "Me dicen Fer (sin mucho misterio) y soy junior iOS Developer, trabajo en el proyecto PBSCO (los bichos gigantes que están en planta baja). \nConsumo cualquier tipo de deporte que se juegue con una pelota, preferentemente fútbol o basket. Mi comida favorita son los ravioles y el asado de tira";
const juanDesc =
  "Hola soy Juan Giorello! Además de ser uno de los 3 socios fundadores, mi rol dentro de Hattrick es de COO (Chief operations officer) y además trabajo en varios proyectos como developer y team lead.\nMi hobbies son Mountain Bike (no lo hago hace mil años) y autos en general. Mi menú favorito: últimamente, milanesa es la que va. Mis amigos dicen que soy malo para los deportes y no los culpo ";
const gastonDesc =
  "Soy Gaston, pero me dicen Tonga. Soy uno de los co-founder y además CTO (Chief technical officer) y  Mobile developer. Mis hobbies son el Auriblanco y Peñarol. Mi comida favorita: lasagna y panqueques de dulce de leche. \nMis amigos dicen de mi que soy terco y pa mi que no es así.";
const zetaDesc =
  "Mi nombre es Martín, pero me dicen Zeta (por mi apellido). Soy hincha y socio de Nacional. Mi plato favorito es el asado (jugoso). No tengo muchos hobbies. Antes jugaba al Xbox y me encantaba, pero desde que soy Papá la consola está ahí juntando polvo en casa. Me gusta leer, aunque no tengo mucha consistencia y termino leyendo casi solamente en vacaciones. \nMis amigos dicen que me encanta discutir, y un poco tienen razón, no te voy a decir que no.";
const gaetaDesc =
  "Me dicen Gaeta, soy desarrollador Full Stack, pero por lo general me enfoco más en el Frontend usando React. Me gustan los deportes, juego fútbol y volley, y cada tanto al tenis. También disfruto mucho de jugar al Ping Pong en la oficina. Mi comida favorita es la Pizza, y también los Sorrentinos. \nEn cuanto a hobbies, me gusta mirar series y a veces algún que otro partido de fútbol. No me gusta mucho leer, prefiero ver videos sobre ciertos temas que necesite aprender, y recién al final leer algo si es necesario. Mis amigos dicen sobre mí que soy muy ansioso, lo cual en gran parte es cierto.";
const valeDesc =
  "Me llamo Valentina pero me dicen Vale. Me gusta salir a comer con mis amigas o con mi novio, ir al cine, jugar a la compu. \nMi menu favorito: sushi. Mis amigos dicen de mi que soy una persona muy espontánea";
const estebanDesc = 'Él es Esteban , esta de viaje de arquitectura con la novia y por eso todavia no se presenta. \nEs backend developer, fan de los asados y el músico del equipo'

const teamUsers = [
  new TeamUser(
    "Alfredo",
    "QA",
    alfredoDesc,
    alfredoImg,
    "https://www.linkedin.com/in/agomezb/",
    "https://www.instagram.com/ai.gomez_/"
  ),
  new TeamUser(
    "Ines",
    "Office Manager",
    ineDesc,
    ineImg,
    "https://www.linkedin.com/in/mar%C3%ADa-in%C3%A9s-olveira-pintos-86a5141b1/",
    "http://instagram.com/ma.ines1996"
  ),
  new TeamUser(
    "Pablo",
    "Flutter Developer",
    pabloDesc,
    pabloImg,
    "https://www.linkedin.com/in/pablo-darre-8ba4a668/",
    "https://www.instagram.com/pdarre/",
    "https://twitter.com/pablodarre"
  ),
  new TeamUser(
    "Kat",
    "UX/UI Designer",
    katDesc,
    katImg,
    "http://linkedin.com/katyaperalta",
    "http://instagram.com/nuncase"
  ),
  new TeamUser(
    "Marcelo",
    "Android Developer",
    marceDesc,
    marceImg,
    "https://www.linkedin.com/in/marceloseoanenormey/",
    "https://www.instagram.com/marcedsn/",
    "https://twitter.com/mdsn1991"
  ),
  new TeamUser(
    "Maria Eugenia",
    "Business Developer",
    maugeDesc,
    maugeImg,
    "https://www.linkedin.com/in/maria-eugenia-gomez-45905b188/"
  ),
  new TeamUser(
    "Diego",
    "Tech Lead",
    diegoDesc,
    diegoImg,
    "https://www.linkedin.com/in/diego-de-araujo-057a63193/"
  ),
  new TeamUser("Karina", "People Care", karinaDesc, kariImg),
  new TeamUser(
    "Martin B.",
    "QA",
    baumannDesc,
    baumannImg,
    "https://www.linkedin.com/in/martin-baumann-lubbe/"
  ),
  new TeamUser("Joaquín", "Android Developer", joacoDesc, joacoImg),
  new TeamUser("Fernando", "iOS Developer", ferDesc, ferImg),
  new TeamUser("Juan", "COO y Co-Founder", juanDesc, juanImg),
  new TeamUser(
    "Gastón",
    "CTO y Co-Founder",
    gastonDesc,
    tongaImg,
    "https://www.linkedin.com/in/gastonkosut/",
    "https://www.instagram.com/gastonkosut/",
    "https://twitter.com/gastonkosut"
  ),
  new TeamUser(
    "Martin Z.",
    "CEO y Co-Founder",
    zetaDesc,
    martinImg,
    "https://www.linkedin.com/in/martin-zuniga/",
    undefined,
    "https://twitter.com/martin_zuniga88"
  ),
  new TeamUser(
    "Gaetano",
    "React Developer",
    gaetaDesc,
    gaetanoImg,
    "https://www.linkedin.com/in/gaetano-di-russo-b0668b208/"
  ),
  new TeamUser("Valentina", "iOS Developer", valeDesc, valeImg),
  new TeamUser("Esteban", "Full Stack Developer", estebanDesc, estebanImg),
];

const descBenefit1 =
  "Nos importa que cuentes con **excelentes herramientas para trabajar** por eso te brindamos una Macbook que también puede ser utilizada para uso personal (a los dos años es tuya!) y un bono de USD150 para que puedas mejorar tu estación de trabajo.";
const descBenefit2 =
  "Porque sabemos el valor del **tiempo libre**, otorgamos un día de licencia adicional por cada año trabajado y tenemos el día de nuestro cumpleaños libre.";
const descBenefit3 =
  "Para ayudarte a **cuidar tu salud** te pagamos un 70% del gimnasio que elijas. ";
const descBenefit4 =
  "El **crecimiento personal y profesional** es uno de nuestros pilares por eso: Pagamos un % de tus estudios de ingles si quieres perfeccionarte. Otorgamos un bono por graduación a quienes obtienen título intermedio y final de sus carreras profesionales. Brindamos el beneficio de los días por estudio desde el momento de tu ingreso, y además sumamos 3 días más de los que otorga la ley.";
const descBenefit5 =
  "Tenemos una modalidad de trabajo híbrida, porque sabemos que poder trabajar desde casa es una ventaja, pero vernos presencialmente nos encanta!\nY para que no nos cueste venir, tenemos el almuerzo gratis en la oficina,  snacks, café, yerba, mesa de ping pong, PS5 y todo lo que necesites para sentirte cómodo. ";

const beneficios = [
  new Benefit("Herramientas de trabajo", descBenefit1, [teclado, auriculares]),
  new Benefit("Tiempo libre", descBenefit2, [tiempoLibre, cumple]),
  new Benefit("Salud", descBenefit3, [gim1, gim2]),
  new Benefit("Crecimiento personal y profesional", descBenefit4, [
    estudiando,
    tituloUniversitario,
  ]),
  new Benefit("Modalidad de trabajo hibirida", descBenefit5, [
    pingpong,
    trabajoRemoto,
  ]),
];

const descAbout1 =
  "**Gastón**, **Juan** y **Martin** son los fundadores de Hattrick IT. Ellos se hicieron amigos estudiando ingeniería en la UDELAR. Siempre tuvieron la idea de formar su propia empresa y en 2014 decidieron animarse a cumplir su sueño. Empezaron siendo sólo ellos tres y con foco en el desarrollo mobile. \nHoy están felices de ver crecer al equipo y ampliar los servicios que brinda la empresa. Su deseo es que Hattrick pueda crecer de manera sostenible y siendo un lugar donde trabajar, aprender y divertirse siempre vayan de la mano.";
const descAbout2 =
  "En Hattrick nos definimos como un equipo de PROS, una sigla formada por las iniciales en inglés de los cuatro valores que nos identifican:\n **Playfulness**: Se trata de las personas que mantienen una actitud positiva y entusiasta frente a todas las actividades que se le proponen. \n**Respect and Integrity**: Quienes trabajamos en hattrick valoramos las diferencias y por eso mantenemos y defendemos una actitud de tolerancia, dialogo y respeto por las distitnas formas de pensar y opiniones. \n**Ownership**: Buscamos que cada uno de los colaboradores de Hattrick sea proactivo y responsable por el logro de sus objetivos y la resolución de los distintos problemas que se le presentan. \n**Self Growth**: Crecer personal y profesionalmente es una de las prioridades en hattrick y por eso aspiramos a que todos quienes formamos parte del equipo tengamos ese interes por el aprendizaje y crecimiento.";
const descAbout3 =
  "**BetterUp**: es una empresa que brinda coaching a empresas y a personas, empoderando y capacitando para mejorar la performance de los mismos. Hattrick participa en el desarrollo de la aplicación Android. \n**BrainCo**: App que mide onda cerebrales y te enseña a concentrarte. Se desarrollaron unos cuantos juegos usando Lottie. Actualmente la app se programa en Flutter y Unity. \n**Photobooth Supply Co (PBSCO)**: Es una app tipo cabina fotográfica para eventos con base en un iPad y web. Hattrick desarrolla su app para iPad, diseño, testing de app y web, y desarrollo de backend. \n**Relay Response**: Sistema para coordinar un equipo médico al momento de realizar reanimación cardiopulmonar. Cuenta con apps para Android Wear (4 apps distintas para smartwatches) y para tablet. Hacemos el desarrollo de sus apps Android";
const descAbout4 =
  "Tenemos un grupo de whatsapp en el que estamos todos, pero tambien podes llamarnos al **+598 92 819 031**.\n También usamos **Slack** como nuestro principal canal de comunicación interna, ahí podés tener conversaciones individuales o en equipos y participar de los distintos grupos y canales. Te sugerimos  actualizar tu foto de perfil, estar atento a marcar cuando estas ausente y disponible, y usar los threads para responder a conversaciones ya iniciadas. Como canal más formal de comunicación  tenemos el mail, más que nada para la comunicación con el exterior.\n Y dos  veces a la semana (lunes y viernes a las 11hs) nos encontramos en el **standup**: una videollamada por google meet en la que los lunes nos contamos  qué hicimos el fin de semana y los viernes en qué estuvimos trabajando en la semana. Es importante participar de esta instancia ya que es el momento de encuentro entre todos. Para esto siempre camara prendida y microfono apagado mientras no hablamos.";

const aboutHattrick = [
  new AboutHattrickCard("Sobre nosotros!", descAbout1, [historia1, historia2]),
  new AboutHattrickCard(
    "Nuestros valores",
    descAbout2,
    [pros, pros2],
    undefined,
    true
  ),
  new AboutHattrickCard(
    "Principales clientes",
    descAbout3,
    [pbsco, brainco],
    undefined,
    true
  ),
  new AboutHattrickCard("Vias de contacto", descAbout4, [slack, standup]),
];

const slackLink = "https://slack.com/intl/es-uy/";
const clockifyLink = "https://clockify.me/es/";

export class FakeRemoteRespositoryImp implements IRemoteRepository {
  async login(
    email: string,
    password: string
  ): Promise<DataResponse<UserAuth>> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (
          email === fakeValidUser.email &&
          password === fakeValidUser.password
        ) {
          resolve(
            new UserAuth(
              fakeValidUser.username,
              fakeValidUser.email,
              "testToken1234"
            )
          );
        } else if (
          email === validUser.email &&
          password === validUser.password
        ) {
          resolve(
            new UserAuth(validUser.username, validUser.email, "testToken1234")
          );
        } else {
          reject(
            new CustomError(errorCodeEnum.UNAUTHORIZED, "Invalid Credentials")
          );
        }
      }, 300);
    });
  }

  async fetchUserImages(quantity: number): Promise<DataResponse<string[]>> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (quantity >= 0) {
          resolve([
            alfredoImg,
            baumannImg,
            diegoImg,
            estebanImg,
            ferImg,
            gaetanoImg,
            ineImg,
            joacoImg,
            juanImg,
            kariImg,
            katImg,
            marceImg,
            martinImg,
            tongaImg,
          ]);
        } else {
          reject(
            new CustomError(
              errorCodeEnum.BAD_REQUEST,
              "Quantity must be greater or equal to zero"
            )
          );
        }
      }, 200);
    });
  }

  async fetchShirtImages(): Promise<
    DataResponse<{ first: string; second: string }>
  > {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          first: shirt1,
          second: shirt2,
        });
      }, 300);
    });
  }

  async fetchComputerImages(): Promise<
    DataResponse<{ windows: string; mac: string }>
  > {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          windows: windows,
          mac: macbook,
        });
      }, 300);
    });
  }

  async createUserProfile(
    userProfile: UserProfile
  ): Promise<DataResponse<void>> {
    return
  }

  async fetchTeamUsers(): Promise<DataResponse<TeamUser[]>> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(teamUsers);
      }, 300);
    });
  }

  async fetchAboutHattrickCards(): Promise<DataResponse<AboutHattrickCard[]>> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(aboutHattrick);
      }, 300);
    });
  }

  async fetchTeamNumber(): Promise<DataResponse<number>> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(teamUsers.length);
      }, 300);
    });
  }

  async fetchBenefits(): Promise<DataResponse<Benefit[]>> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(beneficios);
      }, 300);
    });
  }

  async fetchTasks(): Promise<DataResponse<Task[]>> {
    console.log(process.env);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve([
          new Task(
            "1",
            "Crear un usuario de Slack",
            `Ingresá a este [link](${slackLink}) para unirte, presentate y contanos algo de vos en el canal #ht-internal.`
          ),
          new Task(
            "2",
            "Agregá tu foto de perfil en slack",
            "Para que te podamos ver e identificar más facilmente, te sugerimos agregar una foto de perfil en Slack"
          ),
          new Task(
            "3",
            "Iniciá sesión en Clockify",
            `Usamos [Clockify](${clockifyLink}) para trackear nuestras horas de trabajo. En tu mail de hattrick vas a encontrar la invitación para sumarte!`
          ),
          new Task(
            "4",
            "Iniciá sesión en bambooHR",
            `Bamboo es la plataforma de HR donde podés gestionar tu licencia, beneficios y acceder a tus recibos entre otras cosas. Encontrá la invitación en tu mail de hattrick!`
          ),
          new Task(
            "5",
            "Descargar Google Chrome",
            `Para tener una mejor experiencia a la hora de navegar y entrar a las videollamadas, te recomendamos descargarte Google Chrome`
          ),
        ]);
      }, 300);
    });
  }

  async fetchTaskSectionImage(): Promise<DataResponse<string>> {
    return new Promise((resolve, reject) => {
      resolve(toDoList);
    });
  }
}