import { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { UserProfile, Benefit, CustomError, errorCodeEnum } from "core";

import { useQuery } from "@tanstack/react-query";
import { queryClient } from "index";

import {useAuthStore} from 'context/authContext'

import { remoteHattrickInfoInteractor, localUserProfileInteractor, localAuthInteractor } from "di";

import { ButtonSectionType } from "components/FinishSection/FinishSection";
import { mapUserProfileToUncompletedSections } from "utils";

type Data = {
  benefits: Benefit[];
  uncompletedSections: ButtonSectionType[];
  completed: boolean;
};

export interface ViewInterface {
  data: Data | undefined;
  title: string;
  subtitle: string;
  layoutId: string;
  titleLayoutId?: string;
  progressBarLayoutId?: string;
  isLoading: boolean;
  isError: boolean;
  error: CustomError | null;
  color: "primary" | "secondary" | "accent1" | "accent2";
  onBackHome: () => void;
  onFinish: () => void;
}

const fetchData = async () => {
  try {
    const benefits =
      (await remoteHattrickInfoInteractor.getBenefits()) as Benefit[];
    const userProfile =
      localUserProfileInteractor.getUserProfile() as UserProfile;
    return {
      benefits,
      uncompletedSections: mapUserProfileToUncompletedSections(userProfile),
      completed: userProfile.benefitsCompleted,
    };
  } catch (error) {
    throw error as CustomError;
  }
};

export default function BenefitsViewModel(): ViewInterface {
  const {setIsLoggedIn} = useAuthStore();
  const { data, isLoading, isError, error } = useQuery<Data, CustomError>(
    ["benefits"],
    fetchData
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (error?.code === errorCodeEnum.SERVER_ERROR) {
      localAuthInteractor.deleteToken();
      localUserProfileInteractor.deleteUserProfile();
      alert("Es necesario voler a autenticarse");
      setIsLoggedIn(false);
    }
  }, [error]);

  const title = "Benefits";
  const subtitle =
    "Estos son algunos de los beneficios que te esperan al ser parte del Hattrick team";
  const layoutId = "benefits";
  const titleLayoutId = layoutId + "-title";
  const progressBarLayoutId = layoutId + "-progress-bar";
  const color = "accent2";

  const backHomeHandler = () => {
    navigate("/home", { state: { elevatedCard: layoutId } });
  };

  const completeSectionHandler = () => {
    try {
      const updatedUser = localUserProfileInteractor.setBenefitsCompleted() as UserProfile;
      queryClient.setQueryData(["benefits"], (prev: Data | undefined) => ({
        ...prev!,
        uncompletedSections: mapUserProfileToUncompletedSections(updatedUser),
        completed: true,
      }));
    } catch (error) {
      throw error as CustomError;
    }
  };

  return {
    data: data ?? undefined,
    title,
    subtitle,
    layoutId,
    titleLayoutId,
    progressBarLayoutId,
    isLoading,
    isError,
    error,
    color,
    onBackHome: backHomeHandler,
    onFinish: completeSectionHandler,
  };
}
