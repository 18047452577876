import { IRemoteRepository, DataResponse } from '../../index'

export class GetTeamNumberUseCase {
    readonly remoteRepository: IRemoteRepository;

    constructor(remoteRepository: IRemoteRepository) {
        this.remoteRepository = remoteRepository;
    }

    async execute(): Promise<DataResponse<number>> {
        return await this.remoteRepository.fetchTeamNumber()
    }
}