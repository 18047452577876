import { memo, useEffect } from "react";
import { motion } from "framer-motion";
import { default as useViewModel } from './NavbarViewModel'
import Logo from "../UI/Logo/Logo";
import UserPreview from "../UI/UserPreview/UserPreview";
import DropDownMenu from "../DropDownMenu/DropDownMenu";
import { useLocation } from "react-router-dom";
 
import classes from "./Navbar.module.scss";

type Props = {
  color?: "primary" | "secondaryBlue" | "secondaryViolet";
  animate?: boolean;
};

function NavbarView({ color }: Props) {
  color = color || 'primary'
  const { user, onLogout } = useViewModel();

  return (
    <div className={`${classes.navbar}`}>
      <Logo color={color == "primary" ? "blue" : "white"} />

      {(user && user.username && user.email && user.profileImg) ? (
        <motion.div
          initial={{ x: "150%" }}
          animate={{ x: 0, transition: { duration: 1 } }}
          className={classes.userPreviewContainer}
        >
          <UserPreview
            name={user.username}
            email={user.email}
            image={user.profileImg}
            color={color == "primary" ? "primary" : "secondary"}
          />

          <DropDownMenu elements={[
            {title: 'Cerrar sesión', onClick: onLogout},
          ]}/>
        </motion.div>
      ) : (
        null
      )}
    </div>
  );
}

export default memo(NavbarView)