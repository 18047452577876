import { memo, useState } from "react";
import { motion } from "framer-motion";

import { useDropzone } from "react-dropzone";

import TextField from "../TextField/TextField";
import Button from "../Buttons/Button";
import ImageResizer from "./ImageResizer";

import classes from "./UploadImagePlaceholder.module.scss";

type Props = {
  onClose: () => void;
  motionId?: string;
  onSaveImage: (base64Img: string) => void;
  onEnd: () => void;
  isCroppedImage?: boolean,
};

export default memo(function UploadImagePlaceholder({
  onClose,
  onSaveImage,
  onEnd,
  isCroppedImage,
}: Props) {
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept: {
      "image/*": [],
    },
    maxFiles: 1,
    multiple: false,
    onDrop: selectImageHandler,
    noClick: true,
    noKeyboard: true,
  });

  const [selectedImage, setSelectedImage] = useState("");

  const closeAndContinueHandler = () => {
    onClose();
    onEnd();
  }

  const dragzoneStyle = isDragActive ? classes.focused : '';

  function selectImageHandler(files: any[]){
    if(files.length === 1){
      setSelectedImage(URL.createObjectURL(files[0]));
    }
	};

  const setCroppedImageHandler = (base64Img: string) => {
    onSaveImage(base64Img);
  };

  return (
    <motion.div className={classes.mainContainer} key="button-image">
      {!selectedImage ? 
        <div
          {...getRootProps()}
          className={`${classes.innerContainer} ${dragzoneStyle}`}
        >
          <div className={classes.textContainer}>
            <TextField
              align="center"
              type="subtitle"
              size="medium"
              color="gray"
              weight="bold"
            >
              Arrastrá tu imágen hasta acá
            </TextField>
            <TextField
              className={classes.subtitle}
              align="center"
              type="paragraph"
              size="large"
              color="gray"
              weight="bold"
            >
              o también podes
            </TextField>
            <input multiple={false} {...getInputProps()} type="file" />
            <Button onClick={open} width="large" color="blue">
              Seleccionar un archivo desde tu dispositivo
            </Button>
          </div>

          <TextField
            align="center"
            type="paragraph"
            size="small"
            color="gray"
            weight="bold"
          >
            Archivos soportados: JPG o PNG menores a 30mb.
          </TextField>

        </div>

        :

        <div className={classes.innerContainer}>
          <ImageResizer selectedImage={selectedImage} onCropImage={setCroppedImageHandler}/>
          <Button disabled={!isCroppedImage} onClick={closeAndContinueHandler} width='large' color="blue">Guardar foto</Button>
        </div>
      }
    </motion.div>
  );
});
