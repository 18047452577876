import { IUserProfileLocalRepository, CustomError, UserProfile, FoodPreferences } from "../../index";

export class SetLocalUserFoodPreferencesUseCase {
    private localRepository: IUserProfileLocalRepository;

    constructor(localRepository: IUserProfileLocalRepository) {
        this.localRepository = localRepository;
    }

    execute(foodPreferences: FoodPreferences): UserProfile | CustomError {
        return this.localRepository.setFoodPreferences(foodPreferences);
    }
}