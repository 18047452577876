import { IRemoteRepository, DataResponse, TeamUser } from '../../index'

export class GetTeamUsersUseCase {
    readonly remoteRepository: IRemoteRepository;

    constructor(remoteRepository: IRemoteRepository) {
        this.remoteRepository = remoteRepository;
    }

    async execute(): Promise<DataResponse<TeamUser[]>> {
        return await this.remoteRepository.fetchTeamUsers()
    }
}