import { IUserProfileLocalRepository, CustomError } from "../../index";

export class DeleteLocalUserProfileUseCase {
    private localRepository: IUserProfileLocalRepository;

    constructor(localRepository: IUserProfileLocalRepository) {
        this.localRepository = localRepository;
    }

    execute(): void | CustomError {
        return this.localRepository.deleteUserProfile();
    }
}