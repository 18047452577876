import { IUserProfileLocalRepository, CustomError, UserProfile } from "../../index";

export class SetBenefitsLocalUserPercentageUseCase {
    private localRepository: IUserProfileLocalRepository;

    constructor(localRepository: IUserProfileLocalRepository) {
        this.localRepository = localRepository;
    }

    execute(percentage: number): UserProfile | CustomError {
        return this.localRepository.setBenefitsPercentage(percentage);
    }
}