import { IRemoteRepository, UserProfile, DataResponse } from '../../index'

export class CreateUserProfileUseCase {
    readonly remoteRepository: IRemoteRepository;

    constructor(remoteRepository: IRemoteRepository) {
        this.remoteRepository = remoteRepository;
    }

    async execute(userProfile: UserProfile): Promise<DataResponse<void>> {
        return await this.remoteRepository.createUserProfile(userProfile);
    }
}