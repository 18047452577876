import React from "react";
import classes from "./TextField.module.scss";
import ReactMarkdown from "react-markdown";

type Props = {
  children: string;
  color:
    | "primary"
    | "secondary"
    | "accent1"
    | "accent2"
    | "black"
    | "white"
    | "gray"
    | "error";
  type: "paragraph" | "title" | "subtitle" | "span";
  weight?: "regular" | "bold" | "black" | "extraBold";
  size?: "small" | "medium" | "large";
  fontSize?: number;
  className?: string;
  align?: "center" | "right" | "left";
};

export default function TextField({
  children,
  color,
  type,
  weight,
  size,
  fontSize,
  className,
  align,
}: Props) {
  size = size ? size : "small";
  weight = weight ? weight : "regular";
  align = align ? align : "left";
  let fontWeight = weight ? weight + "Weight" : "";
  const paragraphs = children.split(/\n\n/);

  switch (type) {
    case "title":
      return (
        <div className={classes.richTextContainer}>
          {paragraphs.map((paragraph, i) => (
            <div
              key={i}
              style={{ fontSize: fontSize }}
              className={`${className ? className : ""} ${classes[align!]} ${
                classes[fontWeight]
              } ${classes[color]} ${classes[type]} ${classes[size!]}`}
            >
              <div className={classes.breakLineContainer}>
                {paragraph.split(/\n/).map((line, i) => (
                  <ReactMarkdown key={i} linkTarget="_blank" children={line} />
                ))}
              </div>
            </div>
          ))}
        </div>
      );

    case "subtitle":
      return (
        <div className={classes.richTextContainer}>
          {paragraphs.map((paragraph, i) => (
            <div
              key={i}
              style={{ fontSize: fontSize }}
              className={`${className ? className : ""} ${classes[align!]} ${
                classes[fontWeight]
              } ${classes[color]} ${classes[type]} ${classes[size!]}`}
            >
              <div className={classes.breakLineContainer}>
                {paragraph.split(/\n/).map((line, i) => (
                  <ReactMarkdown key={i} linkTarget="_blank" children={line} />
                ))}
              </div>
            </div>
          ))}
        </div>
      );

    case "paragraph":
      return (
        <div className={classes.richTextContainer}>
          {paragraphs.map((paragraph, i) => (
            <div
              key={i}
              style={{ fontSize: fontSize }}
              className={`${className ? className : ""} ${classes[align!]} ${
                classes[fontWeight]
              } ${classes[color]} ${classes[type]} ${classes[size!]}`}
            >
              <div className={classes.breakLineContainer}>
                {paragraph.split(/\n/).map((line, i) => (
                  <ReactMarkdown key={i} linkTarget="_blank" children={line} />
                ))}
              </div>
            </div>
          ))}
        </div>
      );

    case "span":
      return (
        <div className={classes.richTextContainer}>
          {paragraphs.map((paragraph, i) => (
            <div
              key={i}
              className={`${className ? className : ""} ${classes[align!]} ${
                classes[color]
              } ${classes[type]}`}
            >
              <ReactMarkdown key={i} linkTarget="_blank" children={paragraph} />
            </div>
          ))}
        </div>
      );
  }
}
