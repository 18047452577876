import { motion } from "framer-motion";

import ProgressBar from "../ProgressBar/ProgressBar";

import classes from "./GenericCard.module.scss";
import TextField from "../TextField/TextField";
import Rectangle from "../Shapes/Rectangle/Rectangle";
import Circle from "../Shapes/Circle/Circle";

type Props = {
  title: string;
  subtitle: string;
  progress: number;
  color: "violet" | "skyblue" | "blue" | "green";
};

export default function GenericCard({
  title,
  subtitle,
  progress,
  color,
}: Props) {
  let shapes: any;

  switch (color) {
    case "violet":
      shapes = (
        <>
          <Circle
            color="white"
            size="medium"
            rightStart
            xPos="15%"
            yPos="-20%"
          />
          <Rectangle color="white" bottomStart xPos="-45%" yPos="8%" />
        </>
      );
      break;

    case "skyblue":
      shapes = (
        <>
          <Rectangle color="white" bottomStart xPos="-41%" />
          <Rectangle color="white" bottomStart xPos="-32%" yPos="8%" />
        </>
      );
      break;

    case "blue":
      shapes = (
        <>
          <Circle color="white" size="small" bottomStart xPos="-45%" yPos="" />
          <Rectangle color="white" xPos="-15%" yPos="-35%" />
        </>
      );
      break;

    case "green":
      shapes = (
        <>
          <Rectangle color="white" xPos="-20%" yPos="-45%" />
          <Rectangle color="white" xPos="-25%" yPos="-30%" />
        </>
      );
      break;
  }

  return (
    <motion.div whileHover={{ scale: 1.025 }} className={classes.mainContainer}>
      <div className={`${classes.innerContainer} ${classes[color]}`}>
        <div className={classes.shapesCenterHelper}>{shapes}</div>

        <div>
          <TextField
            className={classes.title}
            size="medium"
            color="white"
            type="subtitle"
            weight="black"
          >
            {title}
          </TextField>
          {subtitle.split("\\n").map((text, i) => (
            <TextField
              key={i}
              size="large"
              color="white"
              type="paragraph"
              weight="bold"
            >
              {text}
            </TextField>
          ))}
        </div>

        <ProgressBar progress={progress} />
      </div>
    </motion.div>
  );
}
