import { IRemoteRepository, DataResponse } from '../../index'

export class GetTaskSectionImageUseCase {
    readonly remoteRepository: IRemoteRepository;

    constructor(remoteRepository: IRemoteRepository) {
        this.remoteRepository = remoteRepository;
    }

    async execute(): Promise<DataResponse<string>> {
        return await this.remoteRepository.fetchTaskSectionImage();
    }
}