import { useEffect, useState } from 'react'
import { localUserProfileInteractor } from 'di';
import { UserProfile } from 'core'
import GenericCard from "components/UI/GenericCard/GenericCard";

type Props = {
  onClick?: () => void;
  className?: string;
};

export default function MeetTheTeam({onClick, className} : Props) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        if(localUserProfileInteractor.existsUserProfile()){
          const userProfile = localUserProfileInteractor.getUserProfile() as UserProfile;
          setProgress(userProfile.meetTheTeamPercentage)
        }
      } catch (error) {
        console.log(error);
      }
    })()
  }, [])

  return (
    <div onClick={() => onClick} className={className} style={{ width: '100%', height: "100%"}}>
      <GenericCard
        title="Meet the team"
        subtitle="Conocé a los miembros del Hattrick team!"
        color='blue'
        progress={progress}
      />
    </div>
  )
}
