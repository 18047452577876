import { useState, useCallback, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import CardContainer from 'components/UI/CardContainer/CardContainer'

import { AnimatedSectionButton } from 'components'

import classes from "./CardCarousel.module.scss";
import { FinishSectionButton } from "components/UI/FinishSectionButton";

const buttonVariants = {
  hidden: { scale: 0 },
  visible: { scale: 1, transition: { duration: 0.3 } },
  smaller: { scale: 0.9 },
  bigger: { scale: 1.1 },
};

type Props = {
  cardsContent: {element: React.JSXElementConstructor<any>, props?: object}[];
  color: 'primary' | 'secondary' | 'accent1' | 'accent2';
  onChangePage?: (currentPageIndex: number) => void;
  onComplete?: () => void;
  totallyEmpty?: true;
  disabled?: boolean;
  isLoadingRequest?: boolean;
  showFinish?: boolean;
  finishDisabled?: boolean;
};

export default function CardCarousel({
  cardsContent,
  color,
  totallyEmpty,
  onChangePage,
  onComplete,
  disabled,
  isLoadingRequest,
  showFinish,
  finishDisabled,
}: Props) {
  const [currentCard, setCurrentCard] = useState(0);

  const nextCardHandler = useCallback(() => {
    setCurrentCard((prev) => {
      if (prev < cardsContent.length - 1) {
        return prev + 1;
      } else {
        return prev;
      }
    });
  }, []);

  const previousCardHandler = useCallback(() => {
    setCurrentCard((prev) => {
      if (prev > 0) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  }, []);

  useEffect(() => {
    if(onChangePage) {
      onChangePage(currentCard)
    }
  }, [currentCard])

  return (
    <div className={classes.mainContainer}>
      <div className={classes.carouselContainer}>
        <AnimatePresence>
          {currentCard > 0 && (
            <div
              className={`${classes.button} ${classes.left}`}
              onClick={previousCardHandler}
            >
              <AnimatedSectionButton
                direction="left"
                color={color}
              />
            </div>
          )}
        </AnimatePresence>
        {cardsContent.map((cardContent, i) => {
          const Element = cardContent.element;
          const props = cardContent.props;
          return (
          <motion.div
            className={`${classes.cardCarouselContainer} ${
              i !== currentCard ? classes.blur : ""
            }`}
            initial={{
              scale: i === currentCard ? 1 : 0.8,
              left: `${(i - currentCard) * 100}%`,
            }}
            animate={{
              scale: i === currentCard ? 1 : 0.8,
              left: `${(i - currentCard) * 100}%`,
            }}
            key={i}
          >
            <CardContainer totallyEmpty={totallyEmpty}>
              <Element disabled={disabled} {...props} active={i === currentCard}/>
            </CardContainer>
          </motion.div>
        )})}
        <AnimatePresence>
          <div
            className={`${classes.button} ${classes.right}`}
            onClick={nextCardHandler}
          >
            {currentCard < cardsContent.length - 1 ? (
              <AnimatedSectionButton
                direction="right"
                color={color}
              />
            ) : (
              (showFinish) && (
                <div style={{position: 'relative', left: '100%'}}>
                  <FinishSectionButton isLoading={isLoadingRequest} color={color} onClick={onComplete} disabled={finishDisabled}/>
                </div>
              )
            )}
          </div>

        </AnimatePresence>

      </div>

    </div>
  );
}
