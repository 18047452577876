import {ReactComponent as VioletRectangle} from "./violet-rectangle.svg";
import {ReactComponent as GreenRectangle} from "./green-rectangle.svg";
import {ReactComponent as WhiteRectangle} from "./white-rectangle.svg";

type Props = {
  xPos?: string;
  yPos?: string;
  rotate?: string;
  zIndex?: number;
  bottomStart?: boolean;
  rightStart?: boolean;
  color: "green" | "violet" | "white";
  light?: boolean; 
};

export default function Rectangle({ xPos, yPos, rotate, zIndex, bottomStart, rightStart, color, light }: Props) {
  xPos = xPos || "0";
  yPos = yPos || "0";
  rotate = rotate || '0deg'
  zIndex = zIndex ? zIndex : 0;

  let selectedRectangle: any;

  switch (color) {
    case "green":
      selectedRectangle = <GreenRectangle/>;
      break;

    case "violet":
      selectedRectangle = <VioletRectangle/>;
      break;

    case "white":
      selectedRectangle = <WhiteRectangle/>;
      break;
  }

  let initialPosition: object = {};

  if (bottomStart) {
    initialPosition = { ...initialPosition, bottom: 0 };
  } else {
    initialPosition = { ...initialPosition, top: 0 };
  }

  if (rightStart) {
    initialPosition = { ...initialPosition, right: 0 };
  } else {
    initialPosition = { ...initialPosition, left: 0 };
  }

  return (
    <div
      style={{
        transform: `translate(${xPos}, ${yPos}) rotate(${rotate})`,
        zIndex: zIndex,
        ...initialPosition,
        position: 'absolute',
        overflow: 'hidden',
        opacity: light ? '30%' : undefined
      }}
    >
      {selectedRectangle}
    </div>
  );
}
