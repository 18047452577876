import { IUserProfileLocalRepository, CustomError, UserProfile } from "../../index";

export class SaveLocalUserProfileUseCase {
    private localRepository: IUserProfileLocalRepository;

    constructor(localRepository: IUserProfileLocalRepository) {
        this.localRepository = localRepository;
    }

    execute(userProfile: UserProfile): void | CustomError {
        return this.localRepository.saveUserProfile(userProfile);
    }
}