import { IUserProfileLocalRepository, CustomError, UserProfile, ProfileInfo } from "../../index";

export class SetLocalUserProfileInfoUseCase {
    private localRepository: IUserProfileLocalRepository;

    constructor(localRepository: IUserProfileLocalRepository) {
        this.localRepository = localRepository;
    }

    execute(profileInfo: ProfileInfo): UserProfile | CustomError {
        return this.localRepository.setProfileInfo(profileInfo);
    }
}