import { IRemoteRepository, DataResponse , Task } from '../../index'

export class GetTasksUseCase {
    readonly remoteRepository: IRemoteRepository;

    constructor(remoteRepository: IRemoteRepository) {
        this.remoteRepository = remoteRepository;
    }

    async execute(): Promise<DataResponse<Task[]>> {
        return await this.remoteRepository.fetchTasks();
    }
}