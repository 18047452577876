import { UserProfile } from "core";
import { localAuthInteractor, localUserProfileInteractor } from "di";

import LoginView from "pages/Login/LoginView";
import WelcomeView from "pages/Welcome/WelcomeView";
import HomeView from "pages/Home/HomeView";
import OnboardingCompleted from "pages/OnboardingCompleted/OnboardingCompletedView";

import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { useAuthStore } from "context/authContext";

import classes from "./app.module.scss";
import { useEffect, useState } from "react";

function checkWelcomeDone() {
  if (localUserProfileInteractor.existsUserProfile()) {
    const currentUserProfile =
      localUserProfileInteractor.getUserProfile() as UserProfile;
    return Boolean(currentUserProfile.profileImg);
  } else {
    return false;
  }
}

const getAnimatesPathNames = (pathname: string) => {
  if (pathname === "/") return pathname;

  return "/" + pathname.split("/")[1];
};

function App() {
  const {isLoggedIn, setIsLoggedIn} = useAuthStore();
  const location = useLocation();

  useEffect(() => {
    try {
      if(localUserProfileInteractor.existsUserProfile()) {
        setIsLoggedIn(true);
      }
    } catch (error) {
      console.log(error);
    }
  }, [])

  const welcomeDone = checkWelcomeDone();

  console.log(location)
  console.log(isLoggedIn)

  return (
    <div className={classes.mainContainer}>
      <AnimatePresence exitBeforeEnter>
        <Routes
          location={location}
          key={getAnimatesPathNames(location.pathname)}
        >
          <Route
            path="/*"
            element={
              !isLoggedIn ? (
                <LoginView />
              ) : !welcomeDone ? (
                <Navigate to="/welcome" />
              ) : (
                <Navigate to="/home" />
              )
            }
          />
          <Route
            path="/home/*"
            element={
              !isLoggedIn ? (
                <Navigate to="/" />
              ) : welcomeDone ? (
                <HomeView />
              ) : (
                <Navigate to="/welcome" />
              )
            }
          />
          <Route
            path="/welcome"
            element={
              !isLoggedIn ? (
                <Navigate to="/" />
              ) : welcomeDone ? (
                <Navigate to="home" />
              ) : (
                <WelcomeView />
              )
            }
          />
          <Route
            path="/onboarding-completed"
            element={<OnboardingCompleted />}
          />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
