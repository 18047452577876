import { IRemoteRepository, DataResponse , Benefit } from '../../index'

export class GetBenefitsUseCase {
    readonly remoteRepository: IRemoteRepository;

    constructor(remoteRepository: IRemoteRepository) {
        this.remoteRepository = remoteRepository;
    }

    async execute(): Promise<DataResponse<Benefit[]>> {
        return await this.remoteRepository.fetchBenefits();
    }
}