import { motion } from "framer-motion";
import classes from "./ImagesContainer.module.scss";

type Props = {
  position: "left" | "right";
  images: [string, string];
  active?: boolean;
};

const cardMove = 50

const topLeftAnimation = {
    scale: 1.3,
    x: -cardMove,
    top: -cardMove,
}

const topRightAnimation = {
    scale: 1.3,
    x: cardMove,
    top: -cardMove,
}

const bottomLeftAnimation = {
    scale: 1.3,
    x: -cardMove,
    top: cardMove,
}

const bottomRightAnimation = {
    scale: 1.3,
    x: cardMove,
    top: cardMove,
}

export default function ImagesContainer({ position, images, active }: Props) {
  return (
    <div
      className={`${classes.mainContainer} ${classes[position]}`}
    >
      <motion.div
        initial={{ scale: 1, x: 0, y: 0 }}
        animate={active ? position === 'left' ? topLeftAnimation : topRightAnimation : undefined}
        transition={{delay: 0.2}}
        className={`${classes.imageContainer} ${classes.top}`}
      >
        <img src={images[0]} />
      </motion.div>

      <motion.div
        initial={{ scale: 1, x: 0, y: 0 }}
        animate={active ? position === 'left' ? bottomLeftAnimation : bottomRightAnimation : undefined}
        transition={{delay: 0.2}}
        className={`${classes.imageContainer} ${classes.bottom}`}
      >
        <img src={images[1]} />
      </motion.div>
    </div>
  );
}
