import { IRemoteRepository, LoginUseCase } from "../index";

export class RemoteAuthInteractor {
    private remoteRepository: IRemoteRepository;

    private loginUseCase: LoginUseCase;

    constructor(remoteRepository: IRemoteRepository) {
        this.remoteRepository = remoteRepository;

        this.loginUseCase = new LoginUseCase(this.remoteRepository)
    }

    async login(email: string, password: string) {
        return this.loginUseCase.execute(email, password);
    }
}