import { Task as TaskType } from "core";
import { Task } from "./index";

import { FinishSectionButton } from 'components'

import classes from "./TasksContainer.module.scss";

type Props = {
  onAddCompleteTask: (taskNumber: string) => void;
  onRemoveCompleteTask: (taskNumber: string) => void;
  tasks: TaskType[];
  userCompletedTasks: string[];
  imageSrc: string;
  disabled?: boolean;
  disabledFinish?: boolean;
  showFinish?: boolean;
  onFinish?: () => void;
};

export default function TasksContainer({
  onAddCompleteTask,
  onRemoveCompleteTask,
  tasks,
  userCompletedTasks,
  imageSrc,
  disabled,
  disabledFinish,
  showFinish,
  onFinish,
}: Props) {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.imageContainer}>
        <img src={imageSrc} />
      </div>

      <div className={classes.innerContainer}>
        {tasks.map((task) => (
          <Task
            onComplete={() => onAddCompleteTask(task.id)}
            onUncomplete={() => onRemoveCompleteTask(task.id)}
            key={task.id}
            task={task}
            done={Boolean(userCompletedTasks.includes(task.id))}
            disabled={disabled}
          />
        ))}
      </div>

        {showFinish && (
          <div className={classes.finishButton}>
            <FinishSectionButton
              onClick={onFinish}
              disabled={disabledFinish}
            />
          </div>
        )}
    </div>
  );
}
