import {createContext, useContext, useState} from 'react';

const AuthContext = createContext({
    isLoggedIn: false,
    setIsLoggedIn: (isLoggedIn: boolean) => {}
})

export const AuthProvider = ({children}: {children: JSX.Element}) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const store = {
        isLoggedIn,
        setIsLoggedIn
    }

    return (
        <AuthContext.Provider value={store}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthStore = () => {
    const {isLoggedIn, setIsLoggedIn} = useContext(AuthContext)

    return {
        isLoggedIn,
        setIsLoggedIn
    }
}