import { motion } from "framer-motion";
import { TeamUser } from "core";

import { TextField } from "components";

import InstagramIcon from "assets/instagram-icon.svg";
import TwitterIcon from "assets/twitter-icon.svg";
import LinkedinIcon from "assets/linkedin-icon.svg";

import classes from "./TeamUserCard.module.scss";

function FadeIn({ children, hideOverflow }: { children: JSX.Element, hideOverflow?: boolean }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      style={hideOverflow ? {overflow: 'hidden'} : undefined}
    >
      {children}
    </motion.div>
  );
}

type Props = {
  teamUser: TeamUser;
};

export default function TeamUserCard({ teamUser }: Props) {
  return (
    <div className={classes.mainContainer}>
      <FadeIn>
        <div className={classes.titleContainer}>
          <div className={classes.nameAndLinksContainer}>
            <TextField
              className={classes.title}
              type="subtitle"
              size="medium"
              color="primary"
              weight="black"
            >
              {teamUser.name}
            </TextField>

            <div className={classes.linksContainer}>
              {teamUser.linkedinURL && (
                <a target="_blank" href={teamUser.linkedinURL}>
                  <img src={LinkedinIcon} />
                </a>
              )}
              {teamUser.twitterURL && (
                <a target="_blank" href={teamUser.twitterURL}>
                  <img src={TwitterIcon} />
                </a>
              )}
              {teamUser.instagramURL && (
                <a target="_blank" href={teamUser.instagramURL}>
                  <img src={InstagramIcon} />
                </a>
              )}
            </div>
          </div>

          <TextField type="paragraph" size="medium" color="gray" weight="black">
            {teamUser.position}
          </TextField>
        </div>
      </FadeIn>

      <FadeIn hideOverflow>
        <div className={classes.descriptionContainer}>
          <TextField type="paragraph" size="small" color="gray" weight="bold">
            {teamUser.description}
          </TextField>
        </div>
      </FadeIn>
    </div>
  );
}
