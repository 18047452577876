import {
  ExistsLocalUserProfileUseCase,
  GetLocalUserProfileUseCase,
  SaveLocalUserProfileUseCase,
  NewLocalUserProfileUseCase,
  SetLocalUserProfileImageUseCase,
  SetLocalUserProfileInfoUseCase,
  SetLocalUserFoodPreferencesUseCase,
  SetLocalUserShirtSizeUseCase,
  SetLocalUserComputerOSUseCase,
  SetLocalUserAllAboutYouCompletedUseCase,
  SetMeetTheTeamLocalUserPercentageUseCase,
  SetBenefitsLocalUserPercentageUseCase,
  SetLocalUserMeetTheTeamCompletedUseCase,
  SetLocalUserAboutHattrickCompletedUseCase,
  SetLocalUserBenefitsCompletedUseCase,
  SetLocalUserToDoListCompletedUseCase,
  SetAboutHattrickLocalUserPercentageUseCase,
  SetLocalUserNumberOfTasksUseCase,
  AddLocalUserCompletedTaskUseCase,
  RemoveLocalUserCompletedTaskUseCase,
  DeleteLocalUserProfileUseCase,
  IUserProfileLocalRepository,
  UserProfile,
  ProfileInfo,
  CustomError,
  FoodPreferences,
  shirtSizeEnum,
  computerOSEnum,
} from "../index";

export class LocalUserProfileInteractor {
  private localRepository: IUserProfileLocalRepository;

  private existsLocalUserProfileUseCase: ExistsLocalUserProfileUseCase;
  private getLocalUserProfileUseCase: GetLocalUserProfileUseCase;
  private saveLocalUserProfileUseCase: SaveLocalUserProfileUseCase;
  private newLocalUserProfileUseCase: NewLocalUserProfileUseCase;
  private setLocalUserProfileImageUseCase: SetLocalUserProfileImageUseCase;
  private setLocalUserProfileInfoUseCase: SetLocalUserProfileInfoUseCase;
  private setLocalUserFoodPreferencesUseCase: SetLocalUserFoodPreferencesUseCase;
  private setLocalUserShirtSizeUseCase: SetLocalUserShirtSizeUseCase;
  private setLocalUserComputerOSUseCase: SetLocalUserComputerOSUseCase;
  private setLocalUserAllAboutYouCompletedUseCase: SetLocalUserAllAboutYouCompletedUseCase;
  private setMeetTheTeamLocalUserPercentageUseCase: SetMeetTheTeamLocalUserPercentageUseCase;
  private setLocalUserMeetTheTeamCompletedUseCase: SetLocalUserMeetTheTeamCompletedUseCase;
  private setBenefitsLocalUserPercentageUseCase: SetBenefitsLocalUserPercentageUseCase;
  private setAboutHattrickLocalUserPercentageUseCase: SetAboutHattrickLocalUserPercentageUseCase;
  private setLocalUserAboutHattrickCompletedUseCase: SetLocalUserAboutHattrickCompletedUseCase;
  private setLocalUserBenefitsCompletedUseCase: SetLocalUserBenefitsCompletedUseCase;
  private setLocalUserNumberOfTasksUseCase: SetLocalUserNumberOfTasksUseCase;
  private addLocalUserCompletedTaskUseCase: AddLocalUserCompletedTaskUseCase;
  private removeLocalUserCompletedTaskUseCase: RemoveLocalUserCompletedTaskUseCase;
  private deleteLocalUserProfileUseCase: DeleteLocalUserProfileUseCase;
  private setLocalUserToDoListCompletedUseCase: SetLocalUserToDoListCompletedUseCase;

  constructor(localRepository: IUserProfileLocalRepository) {
    this.localRepository = localRepository;

    this.existsLocalUserProfileUseCase = new ExistsLocalUserProfileUseCase(
      this.localRepository
    );
    this.getLocalUserProfileUseCase = new GetLocalUserProfileUseCase(
      this.localRepository
    );
    this.saveLocalUserProfileUseCase = new SaveLocalUserProfileUseCase(
      this.localRepository
    );
    this.newLocalUserProfileUseCase = new NewLocalUserProfileUseCase(
      this.localRepository
    );
    this.setLocalUserProfileImageUseCase = new SetLocalUserProfileImageUseCase(
      this.localRepository
    );
    this.setLocalUserProfileInfoUseCase = new SetLocalUserProfileInfoUseCase(
      this.localRepository
    );
    this.setLocalUserFoodPreferencesUseCase =
      new SetLocalUserFoodPreferencesUseCase(this.localRepository);
    this.setLocalUserShirtSizeUseCase = new SetLocalUserShirtSizeUseCase(
      this.localRepository
    );
    this.setLocalUserComputerOSUseCase = new SetLocalUserComputerOSUseCase(
      this.localRepository
    );
    this.setLocalUserAllAboutYouCompletedUseCase =
      new SetLocalUserAllAboutYouCompletedUseCase(this.localRepository);
    this.setLocalUserMeetTheTeamCompletedUseCase =
      new SetLocalUserMeetTheTeamCompletedUseCase(this.localRepository);
    this.setLocalUserAboutHattrickCompletedUseCase =
      new SetLocalUserAboutHattrickCompletedUseCase(this.localRepository);
    this.setLocalUserBenefitsCompletedUseCase =
      new SetLocalUserBenefitsCompletedUseCase(this.localRepository);
    this.setLocalUserToDoListCompletedUseCase =
      new SetLocalUserToDoListCompletedUseCase(this.localRepository);
    this.setLocalUserNumberOfTasksUseCase =
      new SetLocalUserNumberOfTasksUseCase(this.localRepository);
    this.addLocalUserCompletedTaskUseCase =
      new AddLocalUserCompletedTaskUseCase(this.localRepository);
    this.removeLocalUserCompletedTaskUseCase =
      new RemoveLocalUserCompletedTaskUseCase(this.localRepository);
    this.deleteLocalUserProfileUseCase = new DeleteLocalUserProfileUseCase(
      this.localRepository
    );
    this.setMeetTheTeamLocalUserPercentageUseCase =
      new SetMeetTheTeamLocalUserPercentageUseCase(this.localRepository);
    this.setBenefitsLocalUserPercentageUseCase =
      new SetBenefitsLocalUserPercentageUseCase(this.localRepository);
    this.setAboutHattrickLocalUserPercentageUseCase =
      new SetAboutHattrickLocalUserPercentageUseCase(this.localRepository);
  }

  existsUserProfile(): boolean | CustomError {
    return this.existsLocalUserProfileUseCase.execute();
  }

  getUserProfile(): UserProfile | CustomError {
    return this.getLocalUserProfileUseCase.execute();
  }

  saveUserProfile(userProfile: UserProfile): void | CustomError {
    return this.saveLocalUserProfileUseCase.execute(userProfile);
  }

  newUserProfile(username: string, email: string) {
    return this.newLocalUserProfileUseCase.execute(username, email);
  }

  setProfileImage(profileImage: string): UserProfile | CustomError {
    return this.setLocalUserProfileImageUseCase.execute(profileImage);
  }

  setProfileInfo(profileInfo: ProfileInfo): UserProfile | CustomError {
    return this.setLocalUserProfileInfoUseCase.execute(profileInfo);
  }

  setFoodPreferences(
    foodPreferences: FoodPreferences
  ): UserProfile | CustomError {
    return this.setLocalUserFoodPreferencesUseCase.execute(foodPreferences);
  }

  setShirtSize(shirtSize: shirtSizeEnum): UserProfile | CustomError {
    return this.setLocalUserShirtSizeUseCase.execute(shirtSize);
  }

  setComputerOS(computerOS: computerOSEnum): UserProfile | CustomError {
    return this.setLocalUserComputerOSUseCase.execute(computerOS);
  }

  setAllAboutYouCompleted(): UserProfile | CustomError {
    return this.setLocalUserAllAboutYouCompletedUseCase.execute();
  }

  setMeetTheTeamCompleted(): UserProfile | CustomError {
    return this.setLocalUserMeetTheTeamCompletedUseCase.execute();
  }

  setAboutHattrickCompleted(): UserProfile | CustomError {
    return this.setLocalUserAboutHattrickCompletedUseCase.execute();
  }

  setBenefitsCompleted(): UserProfile | CustomError {
    return this.setLocalUserBenefitsCompletedUseCase.execute();
  }

  setMeetTheTeamPercentage(percentage: number): UserProfile | CustomError {
    return this.setMeetTheTeamLocalUserPercentageUseCase.execute(percentage);
  }

  setAboutHattrickPercentage(percentage: number): UserProfile | CustomError {
    return this.setAboutHattrickLocalUserPercentageUseCase.execute(percentage);
  }

  setToDoListCompleted(): UserProfile | CustomError {
    return this.setLocalUserToDoListCompletedUseCase.execute();
  }

  setBenefitsPercentage(percentage: number): UserProfile | CustomError {
    return this.setBenefitsLocalUserPercentageUseCase.execute(percentage);
  }

  setNumberOfTasks(number: number): UserProfile | CustomError {
    return this.setLocalUserNumberOfTasksUseCase.execute(number);
  }

  addCompletedTask(taskId: string): UserProfile | CustomError {
    return this.addLocalUserCompletedTaskUseCase.execute(taskId);
  }

  removeCompletedTask(taskId: string): UserProfile | CustomError {
    return this.removeLocalUserCompletedTaskUseCase.execute(taskId);
  }

  deleteUserProfile(): void | CustomError {
    return this.deleteLocalUserProfileUseCase.execute();
  }
}
