import { memo } from "react";
import { motion } from "framer-motion";

import classes from "./UploadImageButton.module.scss";

type Props = {
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  motionId?: string;
  image: string;
};

export default memo(function UploadImageButton({
  onClick,
  motionId,
  image,
}: Props) {
  return (
    <motion.button
      onClick={onClick}
      className={classes.uploadImageButtonContainer}
      whileHover={{ scale: 1.1 }}
    >
      {image ? (
        <img className={classes.profileImage} src={image} alt="profile-img" />
      ) : (
        <div className={classes.uploadImageInnerButton}>Subí tu foto</div>
      )}
    </motion.button>
  );
});
