import { memo } from 'react'
import classes from "./Button.module.scss";

type Props = {
  children: string;
  color: "white" | "blue" | "violet";
  width?: 'auto' | 'large'
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  selected?: boolean;
  className?: string;
  disabled?: boolean;
};

export default memo(function Button({
  children,
  color,
  width,
  onClick,
  className,
  disabled
}: Props) {
  width = width || "auto";
  disabled = disabled || false;
  
  return (
    <button
      onClick={onClick}
      className={`${disabled ? classes.disabled : ''} ${className ? className : ""} ${classes.button} ${classes[width]} ${
        classes[color]}`}
    >
      {children}
    </button>
  );
})
