import { TextField, Logo } from "components";

import classes from "./MobileModal.module.scss";

export default function MobileModalView() {
  return (
    <div className={classes.mainContainer}>
        <div className={classes.logoContainer}>
            <Logo color="white" />
        </div>

      <div className={classes.innerContainer}>
        <TextField align='center' weight='bold' color="white" type="subtitle" size="medium">
            Te recomendamos entrar a la pagina desde un ordenador para una mejor
            experiencia!
        </TextField>
      </div>
    </div>
  );
}
