import {
  FakeRemoteRespositoryImp,
  StrapiRemoteRepositoryImp,
  AuthLocalDataRepositoryImp,
  UserProfileLocalRepositoryImp,
  LocalUserProfileInteractor,
  LocalAuthInteractor,
  RemoteHattrickInfoInteractor,
  RemoteAuthInteractor,
} from "core";

const remoteRepository = process.env?.REACT_APP_PRODUCTION === 'true' ? new StrapiRemoteRepositoryImp() : new FakeRemoteRespositoryImp();
const authLocalRepository = new AuthLocalDataRepositoryImp();
const userProfileLocalRepository = new UserProfileLocalRepositoryImp();

export const remoteHattrickInfoInteractor = new RemoteHattrickInfoInteractor(remoteRepository);
export const remoteAuthInteractor = new RemoteAuthInteractor(remoteRepository);

export const localAuthInteractor = new LocalAuthInteractor(authLocalRepository);

export const localUserProfileInteractor = new LocalUserProfileInteractor(userProfileLocalRepository);