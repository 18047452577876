import { useState, memo, useCallback } from 'react'
import { computerOSEnum } from 'core'
import classes from "./Form4.module.scss";

import TextField from "components/UI/TextField/TextField";
import FormContainer from "components/UI/FormContainer/FormContainer";
import ComputerSelector from 'components/UI/ComputerSelector/ComputerSelector'

type Props = {
  title: string;
  color: "primary" | "secondary" | "accent1" | "accent2";
  onChangeForm: (computerOS: computerOSEnum) => void;
  formValues: computerOSEnum;
  computerImages: {
    windows: string;
    mac: string;
  }
  disabled?: boolean;
}

export default memo(function Form1({title, color, onChangeForm, formValues, computerImages, disabled}: Props) {
  const selectComputerHandler = useCallback((computerOS: computerOSEnum) => {
    onChangeForm(computerOS);
  }, [])

  return (
      <FormContainer title={title} color={color}>
        <div className={classes.formContainer}>
          <div className={classes.subtitleContainer}>
            <TextField type="paragraph" size="small" color="gray" weight="bold">
              La comodidad a la hora de trabajar es fundamental, elegí la opción
              que te resulte mejor!
              (Si sos desarrollador deberías elegir Mac)
            </TextField>
          </div>

          <div className={classes.computerContainer}>
            <div className={classes.computerInnerContainer}>
              <ComputerSelector disabled={disabled} image={computerImages.mac} onSelect={selectComputerHandler} selected={formValues === computerOSEnum.mac} computer={computerOSEnum.mac}/>
              <ComputerSelector disabled={disabled} image={computerImages.windows} onSelect={selectComputerHandler} selected={formValues === computerOSEnum.windows} computer={computerOSEnum.windows}/>
            </div>
          </div>
        </div>
      </FormContainer>
  );
})
