export class AboutHattrickCard {
    readonly title: string;
    readonly description1: string;
    readonly description2?: string;
    readonly images: [string, string];
    readonly textPosition?: 'right' | 'left' = 'right';

    constructor(title: string, description1: string, images: [string, string], description2?: string, textPositionRight?: boolean) {
        this.title = title;
        this.description1 = description1;
        this.description2 = description2;
        this.images = images;
        this.textPosition = textPositionRight ? 'right' : 'left';
    }
}