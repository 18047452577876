import { IUserProfileLocalRepository, CustomError, UserProfile } from "../../index";

export class NewLocalUserProfileUseCase {
    private localRepository: IUserProfileLocalRepository;

    constructor(localRepository: IUserProfileLocalRepository) {
        this.localRepository = localRepository;
    }

    execute(username: string, email: string): UserProfile | CustomError {
        return this.localRepository.newUserProfile(username, email)
    }
}