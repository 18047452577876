import { useState, memo } from "react";
import { FoodPreferences } from 'core'

import pencilIcon from "./pencil-icon.svg";

import classes from "./Form2.module.scss";
import TextField from "components/UI/TextField/TextField";
import InputForm from "components/UI/InputForm/InputForm";
import FormContainer from "components/UI/FormContainer/FormContainer";

type Props = {
  title: string;
  color: "primary" | "secondary" | "accent1" | "accent2";
  onChangeForm: (field: string, value: string) => void;
  formValues: FoodPreferences;
  disabled?: boolean;
}

export default memo(function Form1({title, color, onChangeForm, formValues, disabled}: Props) {
  const foodPreferencesValues: FoodPreferences = formValues

  const changeUserDataHandler = (field: string, value: string) => {
    onChangeForm(field, value);
  };

  return (
      <FormContainer title={title} color={color}>
        <div className={classes.formContainer}>
          <div className={classes.subtitleContainer}>
            <TextField type="paragraph" size="small" color="gray" weight="bold">
              Cuando estamos en la oficina nos gusta sentirnos como en casa, por
              eso en nuestra cocina siempre vas a encontrar snacks, fruta, café
              y yerba entre otras cosas.
            </TextField>

            <div className={classes.subtitlePencilContainer}>
              <TextField
                className={classes.subtitlePencil}
                type="paragraph"
                size="small"
                color="gray"
                weight="bold"
              >
                Contanos que te gusta asi lo agregamos a la lista de los
                mandados!
              </TextField>
              <img
                style={{ marginLeft: "5px" }}
                src={pencilIcon}
                alt="pencil-icon"
              />
            </div>
          </div>

          <div className={classes.innerFormContainer}>
            <div className={classes.form}>
              <div className={classes.sideContainer}>
                <div className={classes.foodContainer}>
                  <TextField
                    className={classes.title}
                    color="black"
                    weight="bold"
                    type="paragraph"
                    size="small"
                  >
                    Tienes alguna restricción o preferencia alimentaria?
                  </TextField>

                  <InputForm
                    color="secondary"
                    name="foodRestriction"
                    size="large"
                    value={foodPreferencesValues.foodRestriction}
                    id="foodRestriction"
                    onChange={changeUserDataHandler}
                    disabled={disabled}
                  />
                </div>

                <div className={classes.favoriteContainer}>
                  <TextField
                    color="black"
                    weight="bold"
                    type="paragraph"
                    size="small"
                  >
                    Tomás mate, café, té? 
                  </TextField>
                  <TextField
                    className={classes.title}
                    color="black"
                    weight="bold"
                    type="paragraph"
                    size="small"
                  >
                    Cuáles son tus marcas preferidas?
                  </TextField>

                  <InputForm
                    color="secondary"
                    name="favoriteBrands"
                    size="large"
                    value={foodPreferencesValues.favoriteBrands}
                    id="favoriteBrands"
                    onChange={changeUserDataHandler}
                    disabled={disabled}
                  />
                </div>
              </div>

              <div className={classes.sideContainer}>
                <div>
                  <TextField
                    className={classes.title}
                    color="black"
                    weight="bold"
                    type="paragraph"
                    size="small"
                  >
                    Cuales son tus snacks favoritos?
                  </TextField>

                  <InputForm
                    color="secondary"
                    name="favoriteSnacks"
                    size="textarea"
                    weight="bold"
                    value={foodPreferencesValues.favoriteSnacks}
                    id="favoriteSnacks"
                    onChange={changeUserDataHandler}
                    disabled={disabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormContainer>
  );
})
