import { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";

import { TeamUser } from "core";

import { TeamUserCard, TeamImagesCarousel } from "./index";
import { AnimatedSectionButton, FinishSectionButton } from "components";

import classes from "./UserTeamCarousel.module.scss";

const buttonVariants = {
  hidden: { scale: 0 },
  visible: { scale: 1, transition: { duration: 0.3 } },
  smaller: { scale: 0.9 },
  bigger: { scale: 1.1 },
};

type Props = {
  teamUsers: TeamUser[];
  color: "primary" | "secondary" | "accent1" | "accent2";
  onComplete: () => void;
  onChangeCurrentUser: (currenUserIndex: number) => void;
  showFinish?: boolean;
  disabled?: boolean;
};

export default function UserTeamCarousel({
  teamUsers,
  color,
  onComplete,
  onChangeCurrentUser,
  showFinish,
  disabled,
}: Props) {
  const [currentUser, setCurrentUser] = useState(0);

  const nextUserHandler = useCallback(() => {
    setCurrentUser((prev) => {
      if (prev < teamUsers.length - 1) {
        return prev + 1;
      } else {
        return prev;
      }
    });
  }, []);

  const previousUserHandler = useCallback(() => {
    setCurrentUser((prev) => {
      if (prev > 0) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  }, []);

  useEffect(() => {
    onChangeCurrentUser(currentUser);
  }, [currentUser]);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.carouselContainer}>
        <AnimatePresence>
          {currentUser > 0 && (
            <div
              className={`${classes.button} ${classes.left}`}
              onClick={previousUserHandler}
            >
              <AnimatedSectionButton direction="left" color={color} />
            </div>
          )}
        </AnimatePresence>
        <div className={classes.teamUsersContainer}>
          {teamUsers.map(
            (teamUser, i) =>
              i === currentUser && <TeamUserCard key={i} teamUser={teamUser} />
          )}

          <motion.div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              pointerEvents: "none",
            }}
            initial={{ x: `${-currentUser * 100 + 20}px`, y: "20px" }}
            animate={{ x: `${-currentUser * 100 + 20}px`, y: "20px" }}
          >
            <TeamImagesCarousel
              activeIndex={currentUser}
              teamUsers={teamUsers}
            />
          </motion.div>
        </div>
        <AnimatePresence>
          <div
            className={`${classes.button} ${classes.right}`}
            onClick={nextUserHandler}
          >
            {currentUser < teamUsers.length - 1 ? (
              <AnimatedSectionButton direction="right" color={color} />
            ) : (
              showFinish && (
                <div style={{ position: "relative", left: "100%" }}>
                  <FinishSectionButton
                    color={color}
                    onClick={onComplete}
                    disabled={disabled}
                  />
                </div>
              )
            )}
          </div>
        </AnimatePresence>
      </div>
    </div>
  );
}
