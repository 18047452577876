import { motion } from "framer-motion";

import rightArrowWhite from "assets/right-arrow-white.svg";
import rightArrowViolet from "assets/right-arrow-violet.svg";
import rightFinger from "assets/right-finger.svg";
import checkWhite from "assets/check-white.svg";
import WhiteLoader from "components/UI/Loader/WhiteLoader"
import classes from "./CircularButton.module.scss";

type Props = {
  color: "primary" | "secondary" | "secondary" | "accent1" | "accent2" | "black";
  direction?: "left" | "right" | "up" | "down";
  type?: 'submit' | 'button';
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  icon?: "arrow" | "finger" | "check";
  className?: string;
  ContentClassName?: string;
  grayBackground?: boolean;
};

const arrowVariants = {
    hidden: {scale: 0, transition: {duration: 0}},
    start: {scale: [0, 1.3, 1], transition: {duration: 0.5, delay: 0.5}},
}

export default function CircularAnimatedButton({
  color,
  direction,
  onClick,
  type,
  disabled,
  isLoading,
  icon,
  className,
  ContentClassName,
  grayBackground,
}: Props) {
  let iconSrc;

  direction = direction || "right";
  disabled = disabled || false;

  if (icon == "finger") {
    iconSrc = rightFinger;
  } else if (icon == "check") {
    iconSrc = checkWhite;
  } else {
    iconSrc = color === "black" ? rightArrowWhite : rightArrowViolet;
  }

  return (
      <motion.button
        initial={{ scale: 50 }}
        animate={{scale: 1, transition: {duration: 0.3}}}
        exit={{scale: 50,  backgroundColor: classes.primaryColor}}
        whileHover={disabled ? undefined : {scale: 1.2}}
        type={type}
        onClick={disabled ? (e) => e.preventDefault() : onClick}
        className={`${icon === "check" ? classes.check : ""} ${
          classes.container
        } ${classes[color]} ${grayBackground ? classes.grayBackground : ''} ${className ? className : ""} ${disabled ? classes.disabled : ""}`}
        disabled={disabled}
      >
        {isLoading ? (
          <div className={classes.loadingSpinnerContainer}>
            <WhiteLoader/>
          </div>
        ) : (
          <motion.img
          className={`${ContentClassName} ${classes[color]} ${classes[direction]}`}
          src={iconSrc}
          alt="icon"
          variants={arrowVariants}
          initial='hidden'
          animate='start'
          exit='hidden'
          />
        )}
      </motion.button>

  );
}
