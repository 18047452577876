import {
  IRemoteRepository,
  DataResponse,
  UserAuth,
  UserProfile,
  TeamUser,
  AboutHattrickCard,
  Benefit,
  Task,
  CustomError,
  errorCodeEnum,
} from "core/domain";

import { AuthLocalDataRepositoryImp } from "core/data";

import axios, { AxiosInstance } from "axios";

const baseUrl = process.env.REACT_APP_SERVER_API;

export class StrapiRemoteRepositoryImp implements IRemoteRepository {
  teamUsers: TeamUser[] = [];
  localDataRepository: AuthLocalDataRepositoryImp;
  axiosInstance: AxiosInstance;
  teamImages: typeof Image[] = [];

  constructor() {
    this.localDataRepository = new AuthLocalDataRepositoryImp();

    let token;

    if (this.localDataRepository.isLoggedIn()) {
      token = this.localDataRepository.getToken();
    }

    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: token
        ? {
            Authorization: "Bearer " + token,
          }
        : undefined,
    });
  }

  async login(
    email: string,
    password: string
  ): Promise<DataResponse<UserAuth>> {
    try {
      const { data } = await this.axiosInstance.post("/api/auth/local", {
        identifier: email,
        password: password,
      });

      this.axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data.jwt}`;
      this.localDataRepository.setToken(data.jwt);

      return new UserAuth(data.user.username, data.user.email, data.jwt);
    } catch (error: any) {
      if (error.response.status) {
        throw new CustomError(
          errorCodeEnum.UNAUTHORIZED,
          "Invalid Credentials"
        );
      } else {
        throw new CustomError(
          errorCodeEnum.SERVER_ERROR,
          "Internal server error"
        );
      }
    }
  }

  async fetchTeamNumber(): Promise<DataResponse<number>> {
    try {
      if (this.teamUsers.length === 0) {
        const { data } = await this.axiosInstance.get(
          "/api/user-teams?populate=*"
        );

        this.teamUsers = data.data.map(({ attributes: userData }: any) =>
          this.mapFromTeamUserDTO(userData)
        );
      }

      return this.teamUsers.length;
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        "Internal server error"
      );
    }
  }

  async fetchUserImages(quantity: number): Promise<DataResponse<string[]>> {
    try {
      if (this.teamUsers.length === 0) {
        const { data } = await this.axiosInstance.get(
          "/api/user-teams?populate=*"
        );

        this.teamUsers = data.data.map(({ attributes: userData }: any) =>
          this.mapFromTeamUserDTO(userData)
        );
      }

      const userImages = this.teamUsers.map((teamUser) => teamUser.image);

      //Preload images:
      const imagePromises = userImages.map((image) => new Promise((resolve, reject) => {
        const img = new Image();
        img.src = image;
        img.onload = () => resolve(image);
        img.onabort = () => reject(new CustomError(errorCodeEnum.SERVER_ERROR, 'Unable to load images'))
      }))

      await Promise.all(imagePromises)

      shuffleArray(userImages);
      return userImages.slice(0, quantity);

    } catch (error) {
      console.error(error);
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        "Internal server error"
      );
    }
  }

  async fetchShirtImages(): Promise<
    DataResponse<{ first: string; second: string }>
  > {
    try {
      const { data } = await this.axiosInstance.get(
        "/api/shirt-image?populate=*"
      );

      const shirts = data.data.attributes;

      return {
        first: shirts.first_shirt.data.attributes.url,
        second: shirts.second_shirt.data.attributes.url,
      };
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        "Internal server error"
      );
    }
  }

  async fetchComputerImages(): Promise<
    DataResponse<{ windows: string; mac: string }>
  > {
    try {
      const { data } = await this.axiosInstance.get(
        "/api/computer-image?populate=*"
      );

      const computers = data.data.attributes;

      return {
        windows: computers.windows.data.attributes.url,
        mac: computers.mac.data.attributes.url,
      };
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        "Internal server error"
      );
    }
  }

  async fetchBenefits(): Promise<DataResponse<Benefit[]>> {
    try {
      const { data } = await this.axiosInstance.get("/api/benefits?populate=*");

      return data.data.map(({ attributes }: any) =>
        this.mapFromBenefitDTO(attributes)
      );
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        "Internal server error"
      );
    }
  }

  async createUserProfile(
    userProfile: UserProfile
  ): Promise<DataResponse<void>> {
    try {
      const blobImg = await fetch(userProfile.profileImg!).then(res => res.blob());

      let formData = new FormData();
      formData.append("files", blobImg);

      const { data } = await this.axiosInstance.post("/api/upload/", formData);

      await this.axiosInstance.post("/api/user-profiles", {
        data: {
          username: userProfile.username,
          email: userProfile.email,
          nickname: userProfile.profileInfo?.nickName,
          borndate: userProfile.profileInfo?.bornDate,
          contact_number: userProfile.profileInfo?.contactNumber,
          about_me: userProfile.profileInfo?.aboutMe,
          address: userProfile.profileInfo?.address,
          food_restriction: userProfile.foodPreferences?.foodRestriction,
          favorite_brands: userProfile.foodPreferences?.favoriteBrands,
          favorite_snacks: userProfile.foodPreferences?.favoriteSnacks,
          shirt_size: userProfile.shirtSize,
          computer_os: userProfile.computerOS,
          profile_img: data[0].id
        },
      });
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        "Internal server error"
      );
    }
  }

  async fetchTeamUsers(): Promise<DataResponse<TeamUser[]>> {
    try {
      if (this.teamUsers.length === 0) {
        const { data } = await this.axiosInstance.get(
          "/api/user-teams?populate=*"
        );

        this.teamUsers = data.data.map(({ attributes: userData }: any) =>
          this.mapFromTeamUserDTO(userData)
        );
      }

      return this.teamUsers;
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        "Internal server error"
      );
    }
  }

  async fetchAboutHattrickCards(): Promise<DataResponse<AboutHattrickCard[]>> {
    try {
      const { data } = await this.axiosInstance.get(
        "/api/about-hattricks?populate=*"
      );

      return data.data.map(({ attributes }: any) =>
        this.mapFromAboutHattrickDTO(attributes)
      );
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        "Internal server error"
      );
    }
  }

  async fetchTasks(): Promise<DataResponse<Task[]>> {
    try {
      const { data } = await this.axiosInstance.get("/api/tasks?populate=*");

      return data.data.map(
        (taskInfo: any) =>
          new Task(
            taskInfo.id,
            taskInfo.attributes.title,
            taskInfo.attributes.description
          )
      );
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        "Internal server error"
      );
    }
  }

  async fetchTaskSectionImage(): Promise<DataResponse<string>> {
    try {
      const { data } = await this.axiosInstance.get(
        "/api/task-section-image?populate=*"
      );

      return data.data.attributes.image.data.attributes.url;
    } catch (error) {
      throw new CustomError(
        errorCodeEnum.SERVER_ERROR,
        "Internal server error"
      );
    }
  }

  mapFromTeamUserDTO(userData: any) {
    return new TeamUser(
      userData.name,
      userData.position,
      userData.description,
      userData.image.data.attributes.url,
      userData.linkedin_url,
      userData.instagram_url,
      userData.twitter_url
    );
  }

  mapFromBenefitDTO(benefit: any) {
    return new Benefit(
      benefit.title,
      benefit.description,
      [
        benefit.first_image.data.attributes.url,
        benefit.second_image.data.attributes.url,
      ],
      benefit.extra_image?.data?.attributes?.url ?? undefined
    );
  }

  mapFromAboutHattrickDTO(aboutHattrick: any) {
    return new AboutHattrickCard(
      aboutHattrick.title,
      aboutHattrick.description,
      [
        aboutHattrick.first_image.data.attributes.url,
        aboutHattrick.second_image.data.attributes.url,
      ],
      undefined,
      Boolean(aboutHattrick.text_position === "right")
    );
  }
}

const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};