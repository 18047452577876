import { TeamUserImage } from "./index";
import { TeamUser } from "core";

import classes from "./TeamImagesCarousel.module.scss";

type Props = {
  teamUsers: TeamUser[];
  activeIndex: number;
};

export default function TeamImagesCarousel({ teamUsers, activeIndex }: Props) {
  return (
    <div className={classes.mainContainer}>
      {teamUsers.map((teamUser, i) => (
        <TeamUserImage
          key={i}
          imageSrc={teamUser.image}
          active={i === activeIndex}
        />
      ))}
    </div>
  );
}
