import classes from "./InputForm.module.scss";

type Props = {
  color: "primary" | "secondary";
  onBlurColorBackground?: "white";
  size: "small" | "medium" | "large" | "textarea";
  value: string | undefined;
  id: string;
  name: string;
  weight?: string;
  maxLength?: number
  height?: string;
  width?: string;
  type?: string;
  placeholder?: string;
  rows?: number;
  cols?: number;
  align?: "center" | "left" | "right";
  onChange: (field: string, value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  onFocus?: (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
};

export default function InputForm({
  color,
  size,
  value,
  id,
  name,
  weight,
  maxLength,
  height,
  width,
  type,
  placeholder,
  align,
  onBlurColorBackground,
  onChange,
  onBlur,
  disabled,
  onFocus
}: Props) {
  const textAlign = align ? align : "left";
  const blurColor = onBlurColorBackground;
  weight = weight ? weight : 'regular';

  if(width === 'defaultWidth') width = '300px';
  if(height === 'defaultHeight') height = '155px';

  const preventNotNumericInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'e' || e.key === '+' || e.key === '-' || e.key === '.' || e.key === ',') {
      e.preventDefault();
    }
  }

  if (size == "textarea") {
    return (
      <textarea
        style={{height: height, width: width}}
        id={id}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        readOnly={disabled}
        onBlur={onBlur}
        className={`${blurColor ? classes[blurColor] : ""} ${classes.input} ${
          classes.textarea
        } ${classes[color]} ${classes[weight]} ${disabled ? classes.disabled : ''}`}
        onFocus={onFocus}
      ></textarea>
    );
  }
  return (
    <input
      id={id}
      name={name}
      placeholder={placeholder ? placeholder : ""}
      type={type ? type : "text"}
      onKeyDown={type === 'number' ? preventNotNumericInput : undefined}
      value={value}
      onChange={(e) => onChange(e.target.name, e.target.value)}
      readOnly={disabled}
      onBlur={onBlur}
      className={`${blurColor ? classes[blurColor] : ""} ${
        classes[textAlign]
      } ${classes.input} ${classes[size]} ${classes[color]} ${classes[weight]} ${disabled ? classes.disabled : ''}`}
      onFocus={onFocus}
    />
  );
}
