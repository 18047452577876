import classes from "./CheckBox.module.scss";
import CheckBlue from "assets/check-task-blue.svg";
import CheckWhite from "assets/check-task-white.svg";

type Props = {
  onClick: () => void;
  done: boolean;
  disabled?: boolean;
};

export default function CheckBox({ onClick, done, disabled }: Props) {
  return (
    <div
      className={`${classes.mainContainer} ${done ? classes.done : ""} ${disabled ? classes.disabled : ""}`}
      onClick={disabled ? undefined : onClick}
    >
      <img src={done ? CheckWhite : CheckBlue} />
    </div>
  );
}
