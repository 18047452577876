import classes from './ProgressBar.module.scss'
import TextField from '../TextField/TextField'

import colors from 'theme.module.scss'

type Props = {
    progress: number,
    color?:
    | "primary"
    | "secondary"
    | "accent1"
    | "accent2"
    | "white";
}

export default function ProgressBar({progress, color}: Props) {
  color = color || "white";

  return (
    <div className={classes.progressBarContainer}>
      <div className={classes.progressBar}>
        <div
          style={{ width: `${progress}%` }}
          className={`${classes.progressBarFilled} ${classes[color]}`}
        />
        <div
          style={{ width: `${100}%` }}
          className={`${classes.progressBarUnfilled} ${classes[color]}`}
        />
      </div>

      <TextField color={color} size="small" type="paragraph" weight="black">
        {`${Math.round(progress)}% completado`}
      </TextField>
    </div>
  );
}
