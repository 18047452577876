import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "index";

import {
  ProfileInfo,
  FoodPreferences,
  shirtSizeEnum,
  computerOSEnum,
  errorCodeEnum,
} from "core";

import { useNavigate } from "react-router-dom";
import { CustomError, UserProfile } from "core";

import {useAuthStore} from 'context/authContext'

import {
  remoteHattrickInfoInteractor,
  localUserProfileInteractor,
  localAuthInteractor,
} from "di";

import { mapUserProfileToUncompletedSections } from "utils";

import type { ButtonSectionType } from "components/FinishSection/FinishSection";

type Data = {
  computerImages: {
    windows: string;
    mac: string;
  };
  shirtImages: {
    first: string;
    second: string;
  };
  initialProfileInfo: ProfileInfo | undefined;
  initialFoodPreferences: FoodPreferences | undefined;
  initialShirtSize: shirtSizeEnum | undefined;
  initialComputerOS: computerOSEnum | undefined;
  uncompletedSections: ButtonSectionType[];
  completedPercentage: number;
  completed: boolean;
};

export interface ViewInterface {
  data: Data | undefined;
  title: string;
  subtitle: string;
  layoutId: string;
  titleLayoutId?: string;
  progressBarLayoutId?: string;
  isLoading: boolean;
  isLoadingRequest: boolean;
  isError: boolean;
  error: CustomError | null;
  color: "primary" | "secondary" | "accent1" | "accent2";
  onBackHome: () => void;
  onFinish: () => Promise<void>;
  onSaveProfileInfo: (profileInfo: ProfileInfo) => void;
  onSaveFoodPreferences: (foodPreferences: FoodPreferences) => void;
  onSaveShirtSize: (shirtSize: shirtSizeEnum) => void;
  onSaveComputerOS: (computerOS: computerOSEnum) => void;
}

const fetchData = async () => {
  try {
    const userProfile =
      localUserProfileInteractor.getUserProfile() as UserProfile;
    const shirtImages =
      (await remoteHattrickInfoInteractor.getShirtImages()) as {
        first: string;
        second: string;
      };
    const computerImages =
      (await remoteHattrickInfoInteractor.getComputerImages()) as {
        windows: string;
        mac: string;
      };
    return {
      computerImages,
      shirtImages,
      initialProfileInfo: userProfile.profileInfo,
      initialFoodPreferences: userProfile.foodPreferences,
      initialShirtSize: userProfile.shirtSize,
      initialComputerOS: userProfile.computerOS,
      uncompletedSections: mapUserProfileToUncompletedSections(userProfile),
      completedPercentage: userProfile.allAboutYouPercentage,
      completed: Boolean(userProfile.allAboutYouCompleted),
    };
  } catch (error) {
    throw error as CustomError;
  }
};

export default function AllAboutYouViewModel(): ViewInterface {
  const {setIsLoggedIn} = useAuthStore();
  const [sendingData, setSendingData] = useState(false);

  const { data, isLoading, isError, error } = useQuery<Data, CustomError>(
    ["all-about-you"],
    fetchData
  );

  useEffect(() => {
    if (error?.code === errorCodeEnum.SERVER_ERROR) {
      localAuthInteractor.deleteToken();
      localUserProfileInteractor.deleteUserProfile();
      alert("Es necesario voler a autenticarse");
      setIsLoggedIn(false);
    }
  }, [error]);

  const navigate = useNavigate();

  const title = "All about you";
  const subtitle =
    "Queremos saber sobre vos! Completá este formulario y ayudanos a tener todo listo para recibirte!";
  const layoutId = "all-about-you";
  const titleLayoutId = layoutId + "-title";
  const progressBarLayoutId = layoutId + "-progress-bar";
  const color = "accent1";

  const backHomeHandler = () => {
    navigate("/home", { state: { elevatedCard: layoutId } });
  };

  const completeSectionHandler = async () => {
    try {
      setSendingData(true);
      await remoteHattrickInfoInteractor.createUserProfile(
        localUserProfileInteractor.getUserProfile() as UserProfile
      );

      const updatedUser =
        localUserProfileInteractor.setAllAboutYouCompleted() as UserProfile;
      queryClient.setQueryData(["all-about-you"], (prev: Data | undefined) => ({
        ...prev!,
        uncompletedSections: mapUserProfileToUncompletedSections(updatedUser),
        completed: updatedUser.allAboutYouCompleted,
      }));
    } catch (error) {
      throw error as CustomError;
    } finally {
      setSendingData(false);
    }
  };

  const saveProfileInfoHandler = (profileInfo: ProfileInfo) => {
    try {
      const updatedUser = localUserProfileInteractor.setProfileInfo(
        profileInfo
      ) as UserProfile;
      queryClient.setQueryData(["all-about-you"], (prev: Data | undefined) => ({
        ...prev!,
        initialProfileInfo: updatedUser.profileInfo,
        completedPercentage: updatedUser.allAboutYouPercentage,
      }));
    } catch (error) {
      throw error as CustomError;
    }
  };

  const saveFoodPreferencesHandler = (foodPreferences: FoodPreferences) => {
    try {
      const updatedUser = localUserProfileInteractor.setFoodPreferences(
        foodPreferences
      ) as UserProfile;
      queryClient.setQueryData(["all-about-you"], (prev: Data | undefined) => ({
        ...prev!,
        initialFoodPreferences: updatedUser.foodPreferences,
        completedPercentage: updatedUser.allAboutYouPercentage,
      }));
    } catch (error) {
      throw error as CustomError;
    }
  };

  const saveComputerOSHandler = (computerOS: computerOSEnum) => {
    try {
      const updatedUser = localUserProfileInteractor.setComputerOS(
        computerOS
      ) as UserProfile;
      queryClient.setQueryData(["all-about-you"], (prev: Data | undefined) => ({
        ...prev!,
        initialComputerOS: updatedUser.computerOS,
        completedPercentage: updatedUser.allAboutYouPercentage,
      }));
    } catch (error) {
      throw error as CustomError;
    }
  };

  const saveShirtSizeHandler = (shirtSize: shirtSizeEnum) => {
    try {
      const updatedUser = localUserProfileInteractor.setShirtSize(
        shirtSize
      ) as UserProfile;
      queryClient.setQueryData(["all-about-you"], (prev: Data | undefined) => ({
        ...prev!,
        initialShirtSize: updatedUser.shirtSize,
        completedPercentage: updatedUser.allAboutYouPercentage,
      }));
    } catch (error) {
      throw error as CustomError;
    }
  };

  return {
    data: data ?? undefined,
    title,
    subtitle,
    layoutId,
    titleLayoutId,
    progressBarLayoutId,
    isLoading,
    isLoadingRequest: sendingData,
    isError,
    error,
    color,
    onBackHome: backHomeHandler,
    onFinish: completeSectionHandler,
    onSaveProfileInfo: saveProfileInfoHandler,
    onSaveFoodPreferences: saveFoodPreferencesHandler,
    onSaveShirtSize: saveShirtSizeHandler,
    onSaveComputerOS: saveComputerOSHandler,
  };
}
