export class UserAuth {
    readonly username: string;
    readonly email: string;
    readonly token: string;

    constructor(username: string, email: string, token: string) {
        this.username = username;
        this.email = email;
        this.token = token;
    }
}