import { IRemoteRepository, DataResponse, AboutHattrickCard } from '../../index'

export class GetAboutHattrickCardsUseCase {
    readonly remoteRepository: IRemoteRepository;

    constructor(remoteRepository: IRemoteRepository) {
        this.remoteRepository = remoteRepository;
    }

    async execute(): Promise<DataResponse<AboutHattrickCard[]>> {
        return await this.remoteRepository.fetchAboutHattrickCards()
    }
}