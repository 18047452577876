export class Benefit {
    readonly title: string;
    readonly description: string;
    readonly images: [string, string];
    readonly extraImage?: string;

    constructor(title: string, description: string, images: [string, string], extraImage?: string) {
        this.title = title;
        this.description = description;
        this.images = images;
        this.extraImage = extraImage
    }
}