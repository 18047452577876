import { IAuthLocalDataRepository, CustomError, TokenType } from '../../index'

export class IsLoggedInUseCase {
    private localAuthRepository: IAuthLocalDataRepository;

    constructor(localAuthRepository: IAuthLocalDataRepository) {
        this.localAuthRepository = localAuthRepository
    }

    execute(): boolean | CustomError {
        return this.localAuthRepository.isLoggedIn();
    }
}