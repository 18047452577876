import { useState, useEffect } from "react";
import { default as useViewModel } from "./AboutHattrickViewModel";
import { PageSection, CardCarousel, FinishSection } from "components";
import Card from "./components/Card";
import { Rectangle, Circle } from "components/UI";

export default function AboutHattrickView() {
  const [showFinishSection, setShowFinishSection] = useState(false);
  const [completedPercentage, setCompletedPercentage] = useState(0);

  const {
    data,
    title,
    subtitle,
    layoutId,
    titleLayoutId,
    progressBarLayoutId,
    isLoading,
    isError,
    error,
    color,
    onBackHome,
    onFinish,
  } = useViewModel();

  useEffect(() => {
    if (data) {
      setCompletedPercentage((prev) => (data.completed ? 100 : prev));
    }
  }, [data]);

  const onChangeCurrentPage = (currentUserIndex: number) => {
    if (!data?.completed) {
      setCompletedPercentage(
        ((currentUserIndex + 1) / data!.aboutHattrickCardsInfo.length) * 99
      );
    }
  };

  const finishSectionHandler = () => {
    onFinish();
    setShowFinishSection(true);
  };

  return (
    <PageSection
      data={
        data
          ? {
              title: title,
              subtitle: subtitle,
              color: color,
              completedPercentage: completedPercentage,
            }
          : undefined
      }
      layoutId={layoutId}
      onBack={onBackHome}
      isLoading={isLoading}
      isError={isError}
      error={error}
      hideElements={showFinishSection}
      titleLayoutId={titleLayoutId}
      progressBarLayoutId={progressBarLayoutId}
    >
      <>
        {showFinishSection ? (
          <FinishSection
            title={title}
            color={color}
            sections={data!.uncompletedSections}
            titleLayoutId={titleLayoutId}
            progressBarLayoutId={progressBarLayoutId}
          />
        ) : (
          data && (
            <CardCarousel
              cardsContent={data.aboutHattrickCardsInfo.map((cardContent) => ({
                element: Card,
                props: {
                  title: cardContent.title,
                  color: color,
                  images: cardContent.images,
                  firstParagraph: cardContent.description1,
                  secondParagraph: cardContent.description2,
                  textPosition: cardContent.textPosition,
                },
              }))}
              onComplete={finishSectionHandler}
              onChangePage={onChangeCurrentPage}
              showFinish={!data?.completed}
              disabled={data?.completed}
              color={color}
            />
          )
        )}

        <div style={{ zIndex: -1 }}>
          <Circle
            bottomStart
            rightStart
            color="green"
            light
            xPos="-30%"
            yPos="25%"
          />
          <Rectangle
            bottomStart
            color="violet"
            light
            xPos="-8%"
            yPos="55%"
            rotate="45deg"
          />
        </div>
      </>
    </PageSection>
  );
}
