import { IUserProfileLocalRepository, CustomError, UserProfile } from "../../index";

export class RemoveLocalUserCompletedTaskUseCase {
    private localRepository: IUserProfileLocalRepository;

    constructor(localRepository: IUserProfileLocalRepository) {
        this.localRepository = localRepository;
    }

    execute(taskId: string): UserProfile | CustomError {
        return this.localRepository.removeCompletedTask(taskId);
    }
}