import { memo } from 'react'
import classes from "./ButtonSelector.module.scss";

type Props = {
  children: string;
  color: "white" | "blue" | "violet";
  onClick?: (childrenValue: string) => void;
  selected?: boolean;
  className?: string;
  disabled?: boolean;
};

export default memo(function ButtonSelector({
  children,
  color,
  onClick,
  selected,
  className,
  disabled
}: Props) {
  selected = selected || false;

  return (
    <button
      onClick={() => (onClick ? onClick(children) : null)}
      className={`${className ? className : ""} ${classes.button} ${
        classes[color]
      } ${selected ? classes.selected : ""}`}
      disabled={disabled}
    >
      {children}
    </button>
  );
})
