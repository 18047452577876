import {
  IRemoteRepository,
  DataResponse,
  GetTeamNumberUseCase,
  GetTeamImagesUseCase,
  GetShirtImagesUseCase,
  GetComputerImagesUseCase,
  GetAboutHattrickCardsUseCase,
  GetTeamUsersUseCase,
  CreateUserProfileUseCase,
  GetBenefitsUseCase,
  GetTasksUseCase,
  GetTaskSectionImageUseCase,

  AboutHattrickCard,
  TeamUser,
  UserProfile,
  Benefit,
  Task,
} from "../index";

export class RemoteHattrickInfoInteractor {
  private remoteRepository: IRemoteRepository;

  private getTeamNumberUseCase: GetTeamNumberUseCase;
  private getTeamImagesUseCase: GetTeamImagesUseCase;
  private getShirtImagesUseCase: GetShirtImagesUseCase;
  private getComputerImagesUseCase: GetComputerImagesUseCase;
  private getAboutHattrickCardsUseCase: GetAboutHattrickCardsUseCase;
  private getTeamUsersUseCase: GetTeamUsersUseCase;
  private createUserProfileUseCase: CreateUserProfileUseCase;
  private getBenefitsUseCase: GetBenefitsUseCase;
  private getTasksUseCase: GetTasksUseCase;
  private getTaskSectionImageUseCase: GetTaskSectionImageUseCase;


  constructor(remoteRepository: IRemoteRepository) {
    this.remoteRepository = remoteRepository;

    this.getTeamNumberUseCase = new GetTeamNumberUseCase(this.remoteRepository);
    this.getTeamImagesUseCase = new GetTeamImagesUseCase(this.remoteRepository);
    this.getShirtImagesUseCase = new GetShirtImagesUseCase(this.remoteRepository);
    this.getComputerImagesUseCase = new GetComputerImagesUseCase(this.remoteRepository);
    this.getAboutHattrickCardsUseCase = new GetAboutHattrickCardsUseCase(
      this.remoteRepository
    );
    this.getTeamUsersUseCase = new GetTeamUsersUseCase(this.remoteRepository);
    this.createUserProfileUseCase = new CreateUserProfileUseCase(
      this.remoteRepository
    );
    this.getBenefitsUseCase = new GetBenefitsUseCase(this.remoteRepository);
    this.getTasksUseCase = new GetTasksUseCase(this.remoteRepository);
    this.getTaskSectionImageUseCase = new GetTaskSectionImageUseCase(this.remoteRepository);
  }

  async getTeamNumber(): Promise<DataResponse<number>> {
    return await this.getTeamNumberUseCase.execute();
  }

  async getTeamImages(quantity: number = 14): Promise<DataResponse<string[]>> {
    return await this.getTeamImagesUseCase.execute(quantity);
  }

  async getShirtImages(): Promise<DataResponse<{first: string, second: string}>> {
    return await this.getShirtImagesUseCase.execute();
  }

  async getComputerImages(): Promise<DataResponse<{windows: string, mac: string}>> {
    return await this.getComputerImagesUseCase.execute();
  }

  async getAboutHattrickCards(): Promise<DataResponse<AboutHattrickCard[]>> {
    return this.getAboutHattrickCardsUseCase.execute();
  }

  async getTeamUsers(): Promise<DataResponse<TeamUser[]>> {
    return this.getTeamUsersUseCase.execute();
  }

  async createUserProfile(newUserProfile: UserProfile): Promise<DataResponse<void>> {
    return this.createUserProfileUseCase.execute(newUserProfile);
  }

  async getBenefits(): Promise<DataResponse<Benefit[]>> {
    return this.getBenefitsUseCase.execute();
  }

  async getTasks(): Promise<DataResponse<Task[]>> {
    return this.getTasksUseCase.execute();
  }

  async getTaskSectionImage(): Promise<DataResponse<string>> {
    return this.getTaskSectionImageUseCase.execute();
  }
}
