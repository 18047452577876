import { memo } from 'react'
import { computerOSEnum } from 'core'

import classes from './ComputerSelector.module.scss'
import CircularButton from '../Buttons/CircularButton'

type Props = {
  computer: computerOSEnum;
  onSelect: (value: computerOSEnum) => void;
  selected?: boolean;
  image: string;
  disabled?: boolean
}

export default memo(function ComputerSelector({computer, selected, onSelect, image, disabled}: Props) {
  return (
    <div className={classes.container}>
        <div className={classes.imageContainer}>
            <div className={classes.image}>
                <img src={image} alt={computer}/>
            </div>

            <div onClick={disabled ? undefined : () => onSelect(computer)} className={`${classes.buttonContainer} ${disabled ? classes.disabled : ''}`}>
              <div className={`${classes.buttonInnerContainer} ${selected ? classes.selected : ''} ${selected ? classes.selected : ''}`}>
                <CircularButton disabled={disabled} className={`${classes.button}`} color='secondary' direction='up' icon='finger'/>
                <CircularButton disabled={disabled} className={`${classes.button} ${classes.back}`} color='secondary' icon='check'/>
              </div>
            </div>
        </div>
    </div>
  )
})
