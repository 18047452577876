import { useState, useEffect } from "react";
import { default as useViewModel } from "./MeetTheTeamViewModel";
import { PageSection, FinishSection } from "components";

import { UserTeamCarousel } from "./components";

import { Rectangle, Circle } from "components/UI";

export default function MeetTheTeamView() {
  const [showFinishSection, setShowFinishSection] = useState(false);
  const [completedPercentage, setCompletedPercentage] = useState(0);

  const {
    data,
    title,
    subtitle,
    layoutId,
    titleLayoutId,
    progressBarLayoutId,
    isLoading,
    isError,
    error,
    color,
    onBackHome,
    onFinish,
  } = useViewModel();

  useEffect(() => {
    if (data) {
      setCompletedPercentage((prev) => (data.completed ? 100 : prev));
    }
  }, [data]);

  const onChangeCurrentUser = (currentUserIndex: number) => {
    if (!data?.completed) {
      setCompletedPercentage(
        ((currentUserIndex + 1) / data!.teamUsers.length) * 99
      );
    }
  };

  const finishSectionHandler = () => {
    onFinish();
    setShowFinishSection(true);
  };

  return (
    <PageSection
      data={{
        title: title,
        subtitle: subtitle,
        color: color,
        completedPercentage: completedPercentage,
      }}
      layoutId={layoutId}
      isLoading={isLoading}
      isError={isError}
      error={error}
      onBack={onBackHome}
      hideElements={showFinishSection}
      titleLayoutId={titleLayoutId}
      progressBarLayoutId={progressBarLayoutId}
    >
      <>
        {showFinishSection ? (
          <FinishSection
            title={title}
            color={color}
            sections={data!.uncompletedSections}
            titleLayoutId={titleLayoutId}
            progressBarLayoutId={progressBarLayoutId}
          />
        ) : (
          data && (
            <UserTeamCarousel
              color={color}
              teamUsers={data.teamUsers}
              onChangeCurrentUser={onChangeCurrentUser}
              onComplete={finishSectionHandler}
              showFinish={!data?.completed}
              disabled={data?.completed}
            />
          )
        )}

        <div style={{ zIndex: -1 }}>
          <Circle
            bottomStart
            rightStart
            color="violet"
            light
            xPos="0%"
            yPos="20%"
          />
          <Rectangle bottomStart color="green" light xPos="-10%" yPos="40%" />
        </div>
      </>
    </PageSection>
  );
}
