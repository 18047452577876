import { CustomError, errorCodeEnum } from "core";
import { useState } from "react";

import classes from "./LoginForm.module.scss";

import CircularAnimatedButton from "components/UI/Buttons/CircularAnimatedButton";
import TextField from "components/UI/TextField/TextField";
import InputForm from "components/UI/InputForm/InputForm";

type Props = {
  disabled?: boolean;
  isError: boolean;
  error: CustomError | null;
  onLogin: (pin: string) => void;
  onReset: () => void;
  onLoading: boolean;
};

export default function LoginFormView({
  disabled,
  isError,
  error,
  onLogin,
  onReset,
  onLoading,
}: Props) {
  const [pin, setPin] = useState<string>("");

  const changePinHandler = (_field: string, newPin: string) => {
    const pinRegExp = /^\d{0,6}$/;
    if (pinRegExp.test(newPin)) {
      onReset()
      setPin(newPin);
    }
  };

  const submitLoginHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onLogin(pin);
  };

  return (
    <div className={classes.container}>
      <form onSubmit={submitLoginHandler}>
        <div className={classes.formContainer}>
          <TextField
            align="center"
            color="black"
            weight="black"
            type="subtitle"
            fontSize={22}
          >
            {disabled
              ? "Por favor solicitá un link y código válidos"
              : "Ingresá con tu código Hattrick"}
          </TextField>

          {(isError && !disabled) &&
            (error?.code === errorCodeEnum.UNAUTHORIZED ? (
              <TextField
                align="center"
                color="error"
                weight="bold"
                type="paragraph"
                size="medium"
              >
                Pin o link incorrectos, intenta nuevamente
              </TextField>
            ) : (
              <TextField
                align="center"
                color="error"
                weight="bold"
                type="paragraph"
                size="medium"
              >
                Error del servidor, intenta nuevamente
              </TextField>
          ))}

          <InputForm
            id="pin"
            name="pin"
            placeholder="000000"
            // type="number"
            size="medium"
            weight="black"
            maxLength={4}
            color="primary"
            align="center"
            value={pin}
            onChange={changePinHandler}
            disabled={disabled || onLoading}
            onFocus={onReset}
          />

        </div>

        <CircularAnimatedButton
          className={classes.button}
          direction="right"
          color="black"
          type="submit"
          disabled={disabled}
          grayBackground={disabled}
          isLoading={onLoading}
        />
      </form>
    </div>
  );
}
