import {ReactComponent as BlueCircle} from "./blue-circle.svg"
import {ReactComponent as LargeBlueCircle} from "./large-blue-circle.svg"
import {ReactComponent as GreenCircle} from "./green-circle.svg"
import {ReactComponent as VioletCircle} from "./violet-circle.svg"
import {ReactComponent as WhiteCircleLarge} from "./white-circle-large.svg"
import {ReactComponent as WhiteCircleMedium} from "./white-circle-medium.svg"
import {ReactComponent as WhiteCircleSmall} from "./white-circle-small.svg"

const circles = {
  blue: <BlueCircle/>,
  green: <GreenCircle/>,
  violet: <VioletCircle/>,
  largeblue: <LargeBlueCircle/>,
  largegreen: <GreenCircle/>,
  largeviolet: <VioletCircle/>,
}

type Props = {
  xPos?: string;
  yPos?: string;
  zIndex?: number;
  size?: "small" | "medium" | "large";
  rightStart?: boolean;
  bottomStart?: boolean;
  color: "blue" | "white" | "green" | "violet";
  light?: boolean;
};

export default function Circle({
  xPos,
  yPos,
  zIndex,
  size,
  bottomStart,
  rightStart,
  color,
  light,
}: Props) {
  xPos = xPos ? xPos : "0";
  yPos = yPos ? yPos : "0";
  zIndex = zIndex ? zIndex : 0;
  const circleSize = size === 'large' ? 'large' : "";

  let initialPosition: object = {};

  if (bottomStart) {
    initialPosition = { ...initialPosition, bottom: 0 };
  } else {
    initialPosition = { ...initialPosition, top: 0 };
  }

  if (rightStart) {
    initialPosition = { ...initialPosition, right: 0 };
  } else {
    initialPosition = { ...initialPosition, left: 0 };
  }

  let selectedCircle;

  if (color !== 'white') {

    selectedCircle = circles[(circleSize + color) as 'blue' | 'green' | 'violet'];
  } else {
    switch (size) {
      case "large":
        selectedCircle = <WhiteCircleLarge/>;
        break;

      case "medium":
        selectedCircle = <WhiteCircleMedium/>;
        break;

      case "small":
        selectedCircle = <WhiteCircleSmall/>;
        break;
    }
  }

  return (
    <div
      style={{
        transform: `translate(${xPos}, ${yPos})`,
        zIndex: zIndex,
        ...initialPosition,
        position: 'absolute',
        opacity: light ? '30%' : undefined
      }}
    >
      {selectedCircle}
    </div>
  );
}
