import classes from "./UserPreview.module.scss";

import TextField from "../TextField/TextField";
import IconImage from '../IconImage/IconImage'

type Props = {
  name: string;
  email: string;
  image: string;
  color: "primary" | "secondary";
};

export default function UserPreview({ name, email, image, color }: Props) {
  return (
    <div className={classes.userPreviewContainer}>
      <div className={classes.imageContainer}>
        <IconImage image={image} size='small' color={color}/>
      </div>


      <div className={classes.userInfoContainer}>
        <TextField
          color={color == "primary" ? "black" : "white"}
          type="paragraph"
          size="small"
          weight="black"
        >
          {(name.length > 30) ? name.slice(0, 26).concat('...') : name}
        </TextField>
        <TextField
          color={color == "primary" ? "black" : "white"}
          type="paragraph"
          size="small"
          weight="bold"
        >
          {(email.length > 30) ? email.slice(0, 26).concat('...') : email}
        </TextField>
      </div>
    </div>
  );
}
