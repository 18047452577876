import Lottie from "lottie-react";
import loadingSpinner from "assets/loader-black.json";

export default function BlackLoader() {
  return (
    <div style={{height: '100%', width: '100%'}}>
      <Lottie animationData={loadingSpinner} loop={true} />
    </div>
  );
}
