import { memo, ReactNode } from 'react'

import classes from "./CircularButton.module.scss";

import {ReactComponent as RightArrowViolet} from 'assets/right-arrow-violet.svg';
import {ReactComponent as RightArrowBlue} from 'assets/right-arrow-blue.svg';
import {ReactComponent as RightArrowSkyblue} from 'assets/right-arrow-skyblue.svg';
import {ReactComponent as RightArrowGreen} from 'assets/right-arrow-green.svg';
import {ReactComponent as RightArrowWhite} from 'assets/right-arrow-white.svg';
import {ReactComponent as RightFinger} from 'assets/right-finger.svg';
import {ReactComponent as CheckWhite} from 'assets/check-white.svg';


const svgs = {
  arrows: {
    white: <RightArrowWhite/>,
    accent1: <RightArrowViolet/>,
    primary: <RightArrowBlue/>,
    secondary: <RightArrowSkyblue/>,
    accent2: <RightArrowGreen/>,
  },
  icons: {
    finger: <RightFinger/>,
    checkWhite: <CheckWhite/>,
  }
}

type Props = {
  color?: "primary" | "secondary" | "accent1" | "accent2";
  direction?: "left" | "right" | "up" | "down";
  onClick?: () => void;
  icon?: "arrow" | "finger" | "check";
  className?: string;
  ContentClassName?: string;
  backgroundColor?: 'black' | 'white';
  disabled?: boolean;
  fullShadow?: boolean;
  children?: ReactNode
};

const CircularButton = ({
  color,
  direction,
  onClick,
  icon,
  className,
  ContentClassName,
  backgroundColor,
  disabled,
  children,
  fullShadow,
}: Props) => {
  let iconSrc;
  color = color || 'primary'

  backgroundColor = backgroundColor || 'white'
  direction = direction || "right";

  if (icon == "finger") {
    iconSrc = svgs.icons.finger;
  } else if (icon == "check") {
    iconSrc = svgs.icons.checkWhite;
  } else {
    iconSrc = backgroundColor === "black" ? svgs.arrows.white : svgs.arrows[color];
  }

  return (
    <button disabled={disabled} onClick={disabled ? undefined : onClick} className={`${icon === 'check' ? classes.check : ''} ${classes.container} ${classes[backgroundColor]} ${fullShadow ? classes.fullShadow : ''} ${className ? className : ''}`}>
      {children ? children : 
      <div className={`${classes[direction]} ${classes.iconContainer} ${ContentClassName}`}>
        {iconSrc}
      </div>
      }
    </button>
  );
}

export default memo(CircularButton);