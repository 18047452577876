import classes from './SectionButton.module.scss'

type Props = {
    color: 'primary' | 'secondary' | 'accent1' | 'accent2';
    onClick: () => void;
    children: string;
}

export default function SectionButton({color, onClick, children}: Props) {
  return (
    <button onClick={onClick} className={`${classes.mainContainer} ${classes[color]}`}>{children}</button>
  )
}
