import { useState, memo } from "react";
import { AnimatePresence, motion } from "framer-motion";

import UploadImageButton from "./UploadImageButton";
import UploadImagePlaceholder from "./UploadImagePlaceholder";

type Props = {
  onEnd: (croppedImage: string) => void;
}

export default memo(function UploadImage({onEnd}: Props) {
  const [openUpload, setOpenUpload] = useState(false);
  const [image, setImage] = useState('');
  
  const swithUploadHandler = () => {
    setOpenUpload((prev) => !prev);
  };

  const saveCroppedImageHandler = (image: string) => {
    setImage(image);
  }

  const endUploadImageHandler = () => {
    onEnd(image)
  }
  
  return (
    <AnimatePresence>
      {openUpload ? (
        <motion.div
          key="upload-image-placeholder"
          initial={{ opacity: 0, x: 0, y: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, y: '100vh', transition: {duration: 1}}}
        >
          <UploadImagePlaceholder
            motionId="upload-image"
            onClose={swithUploadHandler}
            onSaveImage={saveCroppedImageHandler}
            onEnd={endUploadImageHandler}
            isCroppedImage={Boolean(image)}
          />
        </motion.div>
      ) : (
        <motion.div key="upload-image-button">
          <UploadImageButton
            onClick={swithUploadHandler}
            image={image}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
});
