import { useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import { queryClient } from "index";

import { UserProfile, AboutHattrickCard, CustomError, errorCodeEnum } from "core";
import { remoteHattrickInfoInteractor, localUserProfileInteractor, localAuthInteractor } from "di";

import {useAuthStore} from 'context/authContext'

import { mapUserProfileToUncompletedSections } from "utils";

import type { ButtonSectionType } from "components/FinishSection/FinishSection";

type Data = {
  aboutHattrickCardsInfo: AboutHattrickCard[];
  uncompletedSections: ButtonSectionType[];
  completed: boolean;
};

export interface ViewInterface {
  data: Data | undefined;
  title: string;
  subtitle: string;
  color: "primary" | "secondary" | "accent1" | "accent2";
  layoutId: string;
  titleLayoutId: string;
  progressBarLayoutId: string;
  error: CustomError | null;
  isError: boolean;
  isLoading: boolean;
  onBackHome: () => void;
  onFinish: () => void;
}

async function fetchData() {
  try {
    const aboutHattrickCardsInfo =
      (await remoteHattrickInfoInteractor.getAboutHattrickCards()) as AboutHattrickCard[];
    const userProfile =
      localUserProfileInteractor.getUserProfile() as UserProfile;
    return {
      aboutHattrickCardsInfo,
      uncompletedSections: mapUserProfileToUncompletedSections(userProfile),
      completed: userProfile.aboutHattrickCompleted,
    };
  } catch (error) {
    throw error;
  }
}

export default function AboutHattrickViewModel(): ViewInterface {
  const {setIsLoggedIn} = useAuthStore();
  const { data, isLoading, isError, error } = useQuery<Data, CustomError>(
    ["about-hattrick-cards"],
    fetchData
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (error?.code === errorCodeEnum.SERVER_ERROR) {
      localAuthInteractor.deleteToken();
      localUserProfileInteractor.deleteUserProfile();
      alert("Es necesario voler a autenticarse");
      setIsLoggedIn(false);
    }
  }, [error]);

  const title = "About Hattrick";
  const subtitle =
    "Conocé nuestra historia, nuestros objetivos y los valores que nos impulsan.";
  const layoutId = "about-hattrick";
  const titleLayoutId = layoutId + "-title";
  const progressBarLayoutId = layoutId + "-progress-bar";
  const color = "secondary";

  const backHomeHandler = () => {
    navigate("/home", { state: { elevatedCard: layoutId } });
  };

  const completeSectionHandler = () => {
    try {
      const updatedUser = localUserProfileInteractor.setAboutHattrickCompleted() as UserProfile;
      queryClient.setQueryData(["about-hattrick-cards"], (prev: Data | undefined) => ({
        ...prev!,
        uncompletedSections: mapUserProfileToUncompletedSections(updatedUser),
        completed: updatedUser.aboutHattrickCompleted,
      }));
    } catch (error) {
      throw error as CustomError;
    }
  };

  return {
    data: data ?? undefined,
    title,
    subtitle,
    color,
    layoutId,
    error,
    isLoading,
    isError,
    titleLayoutId,
    progressBarLayoutId,
    onBackHome: backHomeHandler,
    onFinish: completeSectionHandler,
  };
}
