export class Task {
    readonly id: string;
    readonly title: string;
    readonly description: string;

    constructor(id: string, title: string, description: string) {
        this.id = id;
        this.title = title;
        this.description = description;
    }
}