import { useState, useEffect, memo } from "react";
import {
  ProfileInfo,
  FoodPreferences,
  shirtSizeEnum,
  computerOSEnum,
} from "core";

import { default as useViewModel, ViewInterface } from "./AllAboutYouViewModel";

import { CardCarousel, FinishSection } from "components";

import PageSection from "components/PageSection/PageSection";
import { Form1, Form2, Form3, Form4 } from "./Components";

import Circle from "components/UI/Shapes/Circle/Circle";
import Rectangle from "components/UI/Shapes/Rectangle/Rectangle";

const initialProfileInfoState: ProfileInfo = {
  nickName: "",
  bornDate: null,
  contactNumber: "",
  aboutMe: "",
  address: "",
};

const initialFoodPreferenceState: FoodPreferences = {
  foodRestriction: "",
  favoriteBrands: "",
  favoriteSnacks: "",
};

export default memo(function AllAboutYouView() {
  const {
    data,
    title,
    subtitle,
    layoutId,
    titleLayoutId,
    progressBarLayoutId,
    isLoading,
    isLoadingRequest,
    isError,
    error,
    color,
    onBackHome,
    onFinish,
    onSaveProfileInfo,
    onSaveFoodPreferences,
    onSaveShirtSize,
    onSaveComputerOS,
  }: ViewInterface = useViewModel();

  const [showFinishSection, setShowFinishSection] = useState(false);

  const [profileInfo, setProfileInfo] = useState<ProfileInfo>(
    initialProfileInfoState
  );
  const [foodPreferences, setFoodPreferences] = useState<FoodPreferences>(
    initialFoodPreferenceState
  );
  const [shirtSize, setShirtSize] = useState<shirtSizeEnum | null>(null);
  const [computerOS, setComputerOS] = useState<computerOSEnum | null>(null);

  useEffect(() => {
    setProfileInfo(data?.initialProfileInfo || profileInfo)
    setFoodPreferences(data?.initialFoodPreferences || foodPreferences)
    setShirtSize(data?.initialShirtSize || null)
    setComputerOS(data?.initialComputerOS || null)
  }, [data])


  const changeProfileInfoHandler = (field: string, value: string) => {
    onSaveProfileInfo({
      ...profileInfo,
      [field]: value
    })
  };

  const changeFoodPreferencesHandler = (field: string, value: string) => {
    onSaveFoodPreferences({
      ...foodPreferences,
      [field]: value
    })
  };

  const changeShirtSizeHandler = (shirtValue: shirtSizeEnum) => {
    onSaveShirtSize(shirtValue)
  };

  const changeComputerOSHandler = (computerOS: computerOSEnum) => {
    onSaveComputerOS(computerOS)
  };

  const finishSectionHandler = async () => {
    try {
      await onFinish();
      setShowFinishSection(true);
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <PageSection
      data={
        data
          ? {
              title: title,
              subtitle: subtitle,
              color: color,
              completedPercentage: data.completedPercentage,
            }
          : undefined
      }
      isLoading={isLoading}
      isError={isError}
      error={error}
      layoutId={layoutId}
      onBack={onBackHome}
      hideElements={showFinishSection}
      titleLayoutId={titleLayoutId}
      progressBarLayoutId={progressBarLayoutId}
    >
      <>
        {showFinishSection ? (
          <FinishSection
            title={title}
            color={color}
            sections={data?.uncompletedSections || []}
            titleLayoutId={titleLayoutId}
            progressBarLayoutId={progressBarLayoutId}
          />
        ) : (
          data && (
            <CardCarousel
              color={color}
              cardsContent={[
                {
                  element: Form1,
                  props: {
                    title: "Tu Perfil",
                    color: color,
                    onChangeForm: changeProfileInfoHandler,
                    formValues: profileInfo,
                  },
                },
                {
                  element: Form2,
                  props: {
                    title: "Para que estés a gusto",
                    color: color,
                    onChangeForm: changeFoodPreferencesHandler,
                    formValues: foodPreferences,
                  },
                },
                {
                  element: Form3,
                  props: {
                    title: "Elegí el talle de tu remera!",
                    color: color,
                    onChangeForm: changeShirtSizeHandler,
                    formValues: shirtSize,
                    shirtImages: data.shirtImages,
                  },
                },
                {
                  element: Form4,
                  props: {
                    title: "Mac o Windows?",
                    color: color,
                    onChangeForm: changeComputerOSHandler,
                    formValues: computerOS,
                    computerImages: data.computerImages,
                  },
                },
              ]}
              showFinish={!data?.completed}
              onComplete={finishSectionHandler}
              disabled={data?.completed}
              finishDisabled={data?.completedPercentage < 99}
              isLoadingRequest={isLoadingRequest}
            />
          )
        )}

        <div style={{ zIndex: -1 }}>
          <Circle bottomStart color="blue" light xPos="-10%" yPos="25%" />
          <Rectangle bottomStart color="green" light xPos="30%" yPos="40%" />
        </div>
      </>
    </PageSection>
  );
});
