import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from 'core'
import { NavbarView as Navbar, BackArrow, TextField } from 'components'

import { localUserProfileInteractor } from 'di';

import classes from './OnboardingCompleted.module.scss';

export default function OnboardingCompletedView() {
  const navigate = useNavigate();
  const userName = (localUserProfileInteractor.getUserProfile() as UserProfile).username;

  return (
    <motion.div
      className={classes.mainContainer}
      initial={{opacity: 0}}
      animate={{ opacity: 1}}
      exit={{opacity: 0}}
    >
      <div className={classes.navbarContainer}>
        <Navbar color='secondaryBlue'/>
      </div>

      <TextField color='white' type='title' weight='black'>Onboarding 100% completado!</TextField>
      <TextField color='white' type='subtitle' size='medium' weight='black'>{`Felicitaciones ${userName}, y bienvenid@ al equipo!`}</TextField>

      <div className={classes.backHomeButton}>
        <BackArrow color='white' onClick={() => navigate('/home')} />
      </div>
    </motion.div>
  )
}