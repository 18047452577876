import { useState, useEffect } from "react";
import InputForm from "components/UI/InputForm/InputForm";

type Props = {
  field: string;
  onChangeDate: (field: string, value: string) => void;
  dateValue: string | null;
  disabled?: boolean;
};

export default function BornDateInput({ field, onChangeDate, dateValue, disabled }: Props) {
  return (
    <>
      <InputForm
        color="secondary"
        name="bornDate"
        align="center"
        size="large"
        value={dateValue ?? ''}
        id="date"
        type='date'
        onChange={onChangeDate}
        onFocus={(e) => e.target.select()}
        disabled={disabled}
      />
    </>
  );
}
