export enum errorCodeEnum {
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    SERVER_ERROR = 500,
    BAD_REQUEST = 400,
    FORBIDDEN = 403,
    METHOD_NOT_ALLOWED = 405,
}

export class CustomError extends Error {
    code: errorCodeEnum;
    description: string;

    constructor(code: errorCodeEnum, description: string){
        super(description);
        this.code = code;
        this.description = description;
    }
}

