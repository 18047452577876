import { ReactNode } from "react";
import CircularButton from "components/UI/Buttons/CircularButton";
import { motion } from "framer-motion";

const buttonVariants = {
  hidden: { scale: 0 },
  visible: { scale: 1, transition: { duration: 0.3 } },
  smaller: { scale: 0.9 },
  bigger: { scale: 1.1 },
};

type Props = {
  color?: "primary" | "secondary" | "accent1" | "accent2";
  direction?: "left" | "right" | "up" | "down";
  onClick?: () => void;
  icon?: "arrow" | "finger" | "check";
  className?: string;
  ContentClassName?: string;
  backgroundColor?: "black" | "white";
  disabled?: boolean;
  fullShadow?: boolean;
  children?: ReactNode;
};

export default function AnimatedSectionButton(props: Props) {
    const {disabled, onClick, ...buttonProps} = props;

  return (
    <motion.div
      variants={disabled ? undefined : buttonVariants}
      style={{ zIndex: 1 }}
      initial="visible"
      animate="visible"
      whileHover="bigger"
      whileTap="smaller"
      exit="hidden"
      onClick={disabled ? undefined : onClick}
    >
      <CircularButton {...buttonProps} disabled={disabled}>{props.children}</CircularButton>
    </motion.div>
  );
}
