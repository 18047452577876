import classes from "./IconImage.module.scss";

type Props = {
    image: string;
    size: 'small' | 'medium' | 'large' | 'xlarge';
    color: 'primary' | 'secondary';
}

export default function IconImage({image, size, color}: Props) {
  return (
    <div className={`${classes[size]} ${classes.imageContainer} ${classes[color]}`}>
      <img src={image} alt='image-person'/>
    </div>
  );
}
