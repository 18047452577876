import { useState, useEffect } from "react";
import { default as useViewModel, ViewInterface } from "./BenefitsViewModel";
import { Benefit } from "core";

import { PageSection, CardCarousel, FinishSection } from "components";
import { Rectangle, Circle } from "components/UI";

import { TwoCardsContainer } from "./components";

function orderBenefitsInTwoCards(benefits: Benefit[]) {
  const result = [];
  const benefitsLength = benefits.length;
  for (let i = 0; i < benefitsLength; i += 2) {
    const twoCardObject = {
      element: TwoCardsContainer,
      props: {
        benefitCard1: benefits[i],
        benefitCard2: i + 1 < benefitsLength ? benefits[i + 1] : undefined,
      },
    };
    result.push(twoCardObject);
  }

  return result;
}

export default function BenefitsView() {
  const [showFinishSection, setShowFinishSection] = useState(false);
  const [completedPercentage, setCompletedPercentage] = useState(0);

  const {
    data,
    title,
    subtitle,
    layoutId,
    titleLayoutId,
    progressBarLayoutId,
    isLoading,
    isError,
    error,
    color,
    onBackHome,
    onFinish,
  }: ViewInterface = useViewModel();

  useEffect(() => {
    if (data) {
      setCompletedPercentage((prev) => (data.completed ? 100 : prev));
    }
  }, [data]);

  const onChangeCurrentPage = (currentPageIndex: number) => {
    if (!data?.completed) {
      const totalPages = Math.ceil(data!.benefits.length / 2);

      setCompletedPercentage(((currentPageIndex + 1) / totalPages) * 99);
    }
  };

  const finishSectionHandler = () => {
    onFinish();
    setShowFinishSection(true);
  };

  return (
    <PageSection
      data={{
        title: title,
        subtitle: subtitle,
        color: color,
        completedPercentage: completedPercentage,
      }}
      isLoading={isLoading}
      isError={isError}
      error={error}
      layoutId={layoutId}
      onBack={onBackHome}
      hideElements={showFinishSection}
      titleLayoutId={titleLayoutId}
      progressBarLayoutId={progressBarLayoutId}
    >
      <>
        {showFinishSection ? (
          <FinishSection
            title={title}
            color={color}
            sections={data!.uncompletedSections}
            titleLayoutId={titleLayoutId}
            progressBarLayoutId={progressBarLayoutId}
          />
        ) : (
          data && (
            <CardCarousel
              totallyEmpty
              color={color}
              cardsContent={orderBenefitsInTwoCards(data.benefits)}
              onChangePage={onChangeCurrentPage}
              onComplete={finishSectionHandler}
              showFinish={!data?.completed}
              disabled={data?.completed}
            />
          )
        )}

        <div style={{ zIndex: -1 }}>
          <Circle
            bottomStart
            rightStart
            color="blue"
            light
            xPos="-30%"
            yPos="30%"
          />
          <Rectangle
            bottomStart
            color="violet"
            light
            xPos="-8%"
            yPos="55%"
            rotate="45deg"
          />
        </div>
      </>
    </PageSection>
  );
}
