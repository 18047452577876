import { IUserProfileLocalRepository, CustomError, UserProfile } from "../../index";

export class SetLocalUserProfileImageUseCase {
    private localRepository: IUserProfileLocalRepository;

    constructor(localRepository: IUserProfileLocalRepository) {
        this.localRepository = localRepository;
    }

    execute(profileImage: string): UserProfile | CustomError {
        return this.localRepository.setProfileImage(profileImage);
    }
}