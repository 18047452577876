import { AnimatedSectionButton, TextField } from "components";
import { BlackLoader } from "components";

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  color?: "primary" | "secondary" | "accent1" | "accent2";
  isLoading?: boolean;
};

export default function FinishSectionButton({
  color,
  onClick,
  disabled,
  isLoading,
}: Props) {
  return (
    <div onClick={disabled ? undefined : onClick}>
      <AnimatedSectionButton fullShadow disabled={disabled}>
        {isLoading ? (
          <BlackLoader />
        ) : (
          <TextField
            weight="black"
            color={disabled ? "gray" : color || "primary"}
            type="paragraph"
            size="medium"
          >
            DONE
          </TextField>
        )}
      </AnimatedSectionButton>
    </div>
  );
}
