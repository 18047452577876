import { IAuthLocalDataRepository, CustomError, TokenType } from '../../index'

export class SetTokenUseCase {
    private localAuthRepository: IAuthLocalDataRepository;

    constructor(localAuthRepository: IAuthLocalDataRepository) {
        this.localAuthRepository = localAuthRepository
    }

    execute(token: TokenType): void | CustomError {
        return this.localAuthRepository.setToken(token);
    }
}