import hattrickIconBlue from "./hattrick-icon-blue.svg";
import hattrickIconWhite from "./hattrick-icon-white.svg";

import {ReactComponent as HattrickIconBlue} from './hattrick-icon-blue.svg'
import {ReactComponent as HattrickIconWhite} from './hattrick-icon-white.svg'

import classes from "./Logo.module.scss";

import TextField from '../../UI/TextField/TextField'

type Props = {
  color: 'blue' | 'white';
  absolutePos?: boolean;
}

export default function Logo({color, absolutePos}: Props) {

  const style: object = absolutePos ? { position: "absolute", top: "20px", left: "50px" } : {};

  return (
    <div style={style} className={classes.logo}>
      <div className={classes.iconContainer}>
        {color === 'blue' ? <HattrickIconBlue/> : <HattrickIconWhite/>}
      </div>
      <TextField type='paragraph' weight='bold' color={color == 'blue' ? 'gray' : 'white'} fontSize={12}>Onboarding platform</TextField>
    </div>
  );
}
