import classes from './CardContainer.module.scss'

type Props = {
  children: JSX.Element;
  totallyEmpty?: true
};

export default function CardContainer({ children, totallyEmpty }: Props) {
  return (
    <div className={`${classes.mainContainer} ${totallyEmpty ? classes.empty : ''}`}>
      {children}
    </div>
  );
}
