import { IUserProfileLocalRepository, CustomError, UserProfile } from "../../index";

export class SetLocalUserNumberOfTasksUseCase {
    private localRepository: IUserProfileLocalRepository;

    constructor(localRepository: IUserProfileLocalRepository) {
        this.localRepository = localRepository;
    }

    execute(number: number): UserProfile | CustomError {
        return this.localRepository.setNumberOfTasks(number);
    }
}