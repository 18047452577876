export class UserProfile {
  readonly username: string;
  readonly email: string;
  profileImg?: string;

  welcomeDone: boolean = false;

  //All About You SECTION
  profileInfo?: ProfileInfo;
  profileInfoCompleted: boolean = false;

  foodPreferences?: FoodPreferences;

  shirtSize?: shirtSizeEnum;
  shirtSizeCompleted: boolean = false;

  computerOS?: computerOSEnum;
  computerOSCompleted: boolean = false;

  allAboutYouPercentage: number = 0;
  allAboutYouCompleted: boolean = false;

  //About Hattrick
  aboutHattrickPercentage: number = 0;
  aboutHattrickCompleted: boolean = false;

  //Meet the team
  meetTheTeamPercentage: number = 0;
  meetTheTeamCompleted: boolean = false;

  //Benefits
  benefitsPercentage: number = 0;
  benefitsCompleted: boolean = false;

  //To Do List
  numberOfTasks: number = 0;
  completedTasks: string[] = [];
  toDoListPercentage: number = 0;
  toDoListCompleted: boolean = false;

  //TOTAL COMPLETED PERCENTAGE
  totalCompletedPercentage: number = 0;

  constructor(username: string, email: string) {
    this.username = username;
    this.email = email;
    this.completedTasks = [];
  }

  static fromJSONObject(jsonObj: any){
    const res = Object.assign(new UserProfile(jsonObj.username, jsonObj.email), jsonObj) as UserProfile;

    return res;
  }

  setProfileImg(profileImg: string) {
    this.profileImg = profileImg;
    if(!this.welcomeDone){
      this.welcomeDone = true;
    }
  }

  //All About You SETTERS
  setProfileInfo(profileInfo: ProfileInfo) {
    this.profileInfo = profileInfo;

    if(isCompletedProfileInfo(profileInfo)){
      this.profileInfoCompleted = true;
    } else {
      this.profileInfoCompleted = false;
    }

    this.reCalculateAllAboutYouPercentage()
  }

  setFoodPreferences(foodPreferences: FoodPreferences) {
    this.foodPreferences = foodPreferences;
  }

  setShirtSize(shirtSize: shirtSizeEnum) {
    this.shirtSize = shirtSize;
    
    if(shirtSize){
      this.shirtSizeCompleted = true;
    } else {
      this.shirtSizeCompleted = false;
    }

    this.reCalculateAllAboutYouPercentage()
  }

  setComputerOS(computerOS: computerOSEnum) {
    this.computerOS = computerOS;

    if(computerOS){
      this.computerOSCompleted = true;
    } else {
      this.computerOSCompleted = false;
    }

    this.reCalculateAllAboutYouPercentage()
  }

  private reCalculateAllAboutYouPercentage() {
    let percentage = 0;
    if(this.profileInfoCompleted) percentage += 50;
    if(this.shirtSizeCompleted) percentage += 25;
    if(this.computerOSCompleted) percentage += 24;

    this.setAllAboutYouPercentage(percentage);
  }

  setAllAboutYouPercentage(percentage: number) {
    this.allAboutYouPercentage = percentage;
    this.reCalculateTotalPercentage()
  }

  setAllAboutYouCompleted() {
    this.allAboutYouCompleted = true;
    this.setAllAboutYouPercentage(100);
  }

  //About Hattrick SETTERS
  setAboutHattrickPercentage(percentage: number) {
    this.aboutHattrickPercentage = percentage;
    this.reCalculateTotalPercentage()
  }

  setAboutHattrickCompleted() {
    this.aboutHattrickCompleted = true;
    this.setAboutHattrickPercentage(100)
  }

  //Meet the team SETTERS
  setMeetTheTeamPercentage(percentage: number) {
    this.meetTheTeamPercentage = percentage;
    this.reCalculateTotalPercentage()
  }

  setMeetTheTeamCompleted() {
    this.meetTheTeamCompleted = true;
    this.setMeetTheTeamPercentage(100)
  }

  //Benefits SETTERS
  setBenefitsPercentage(percentage: number) {
    this.benefitsPercentage = percentage;
    this.reCalculateTotalPercentage()
  }

  setBenefitsCompleted() {
    this.benefitsCompleted = true;
    this.setBenefitsPercentage(100)
  }

  //To Do List SETTERS
  setNumberOfTasks(number: number) {
    this.numberOfTasks = number;
    this.reCalculateToDoListPercentage()
  }

  addCompletedTask(taskId: string) {
    const completedTasks = this.completedTasks;
    if(!completedTasks.includes(taskId)) completedTasks.push(taskId);
    this.reCalculateToDoListPercentage()
  }

  removeCompletedTask(taskId: string) {
    this.completedTasks = this.completedTasks.filter(id => id !== taskId);
    this.reCalculateToDoListPercentage()
  }

  private reCalculateToDoListPercentage() {
    if(this.completedTasks.length > 0){
      const totalPercentage = this.toDoListCompleted ? 100 : 99;
      this.setToDoListPercentage(this.completedTasks.length / this.numberOfTasks * totalPercentage)
    } else {
      this.setToDoListPercentage(0)
    }
  }

  setToDoListPercentage(percentage: number) {
    this.toDoListPercentage = percentage;
    this.reCalculateTotalPercentage();
  }

  setToDoListCompleted() {
    this.toDoListCompleted = true;
    this.setToDoListPercentage(100);
  }

  //Total Percentage SETTERS
  reCalculateTotalPercentage() {
    this.totalCompletedPercentage =
      (this.aboutHattrickPercentage +
      this.allAboutYouPercentage +
      this.benefitsPercentage +
      this.meetTheTeamPercentage +
      this.toDoListPercentage) / 5;
  }
}

export enum shirtSizeEnum {
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
  XXL = "XXL",
}

export enum computerOSEnum {
  windows = "Windows",
  mac = "Mac",
}

export type ProfileInfo = {
  nickName: string | null;
  bornDate: string | null;
  contactNumber: string | null;
  aboutMe: string | null;
  address: string | null;
};

export type FoodPreferences = {
  foodRestriction: string;
  favoriteBrands: string;
  favoriteSnacks: string;
};

export type AboutMeInfo = {
  profileInfo: ProfileInfo;
  foodPreferences: FoodPreferences;
  shirtSize: shirtSizeEnum;
  computerOS: computerOSEnum
}

function existsAndIsValid(value: string | null): boolean {
  return Boolean(value && value.trim().length > 0)
}

function isCompletedProfileInfo(profileInfo: ProfileInfo): boolean {
  return Boolean(existsAndIsValid(profileInfo.nickName) && existsAndIsValid(profileInfo.bornDate) && existsAndIsValid(profileInfo.contactNumber) && existsAndIsValid(profileInfo.address))
}