import { IAuthLocalDataRepository, TokenType, CustomError, errorCodeEnum } from '../../domain'

const tokenKey = 'token'

export class AuthLocalDataRepositoryImp implements IAuthLocalDataRepository {
    getToken(): TokenType | CustomError {
        const token = localStorage.getItem(tokenKey);
        if(token) {
            return JSON.parse(token) as TokenType
        } else {
            throw new CustomError(errorCodeEnum.NOT_FOUND, 'Token not found')
        }
    }

    setToken(token: TokenType): void | CustomError{
        localStorage.setItem(tokenKey, JSON.stringify(token))
    }

    isLoggedIn(): boolean | CustomError {
        return Boolean(localStorage.getItem(tokenKey))
    }

    deleteToken(): void | CustomError {
        localStorage.removeItem(tokenKey)
    }
}