import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  remoteAuthInteractor,
  localAuthInteractor,
  localUserProfileInteractor,
  remoteHattrickInfoInteractor,
} from "di";
import { CustomError, UserAuth } from "core";

import { useAuthStore } from "context/authContext";

export interface ViewInterface {
  isValidUrl: boolean;
  isError: boolean;
  error: CustomError | null;
  isLoading: boolean;
  onLogin: (pin: string) => void;
  onResetForm: () => void;
}

export default function LoginViewModel(): ViewInterface {
  const { setIsLoggedIn } = useAuthStore();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<CustomError | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name");
  const domain = searchParams.get("domain");
  const email = `${name}@${domain}`;

  const isError = Boolean(error);

  const isValidUrl = Boolean(name && domain);

  const loginHandler = async (pin: string) => {
    setIsLoading(true);
    try {
      const user = (await remoteAuthInteractor.login(email, pin)) as UserAuth;

      localAuthInteractor.setToken(user.token);

      if (!localUserProfileInteractor.existsUserProfile()) {
        localUserProfileInteractor.newUserProfile(user.username, user.email);
      }

      await remoteHattrickInfoInteractor.getTeamImages();

      setIsLoggedIn(true);

      navigate("/welcome");
    } catch (error) {
      localAuthInteractor.deleteToken();
      localUserProfileInteractor.deleteUserProfile();
      setError(error as CustomError);
    } finally {
      setIsLoading(false);
    }
  };

  const resetFormStatusHandler = () => {
    setError(null);
    setIsLoading(false);
  };

  return {
    isValidUrl,
    isError,
    error,
    isLoading,
    onLogin: loginHandler,
    onResetForm: resetFormStatusHandler,
  };
}
