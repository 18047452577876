import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "index";
import { CustomError, Task, UserProfile, errorCodeEnum } from "core";

import { localUserProfileInteractor, remoteHattrickInfoInteractor, localAuthInteractor } from "di";

import {useAuthStore} from 'context/authContext'

import { mapUserProfileToUncompletedSections } from "utils";

import type { ButtonSectionType } from "components/FinishSection/FinishSection";

export type Data = {
  tasks: Task[];
  userCompletedTaskIds: string[];
  taskSectionImage: string;
  completedPercentage: number;
  completed: boolean;
  uncompletedSections: ButtonSectionType[];
};

export interface ViewInterface {
  data: Data | undefined;
  title: string;
  subtitle: string;
  layoutId: string;
  titleLayoutId?: string;
  progressBarLayoutId?: string;
  color: "primary" | "secondary" | "accent1" | "accent2";
  isLoading: boolean;
  isError: boolean;
  error: CustomError | null;
  onBackHome: () => void;
  onAddCompleteTask: (taskId: string) => void;
  onRemoveCompleteTask: (taskId: string) => void;
  onFinish: () => void;
}

async function fetchData() {
  try {
    const tasks = (await remoteHattrickInfoInteractor.getTasks()) as Task[];
    const taskSectionImage =
      (await remoteHattrickInfoInteractor.getTaskSectionImage()) as string;
    const userProfile =
      (await localUserProfileInteractor.getUserProfile()) as UserProfile;
    await localUserProfileInteractor.setNumberOfTasks(tasks.length);

    return {
      tasks: tasks,
      userCompletedTaskIds: userProfile.completedTasks,
      taskSectionImage,
      completedPercentage: userProfile.toDoListPercentage,
      completed: userProfile.toDoListCompleted,
      uncompletedSections: mapUserProfileToUncompletedSections(userProfile)
    } as Data;
  } catch (error) {
    throw error as CustomError;
  }
}

export default function ToDoListViewModel(): ViewInterface {
  const {setIsLoggedIn} = useAuthStore();
  const { data, isLoading, isError, error } = useQuery<Data, CustomError>(
    ["to-do-list"],
    fetchData
  );

  const navigate = useNavigate();

  const title = "To Do list";
  const subtitle = "Arrancá con todo! Dejemos todo listo para tu primer día.";
  const layoutId = "to-do-list";
  const titleLayoutId = layoutId + "-title";
  const progressBarLayoutId = layoutId + "-progress-bar";
  const color = "primary";

  useEffect(() => {
    if (error?.code === errorCodeEnum.SERVER_ERROR) {
      localAuthInteractor.deleteToken();
      localUserProfileInteractor.deleteUserProfile();
      alert("Es necesario voler a autenticarse");
      setIsLoggedIn(false);
    }
  }, [error]);

  const backHomeHandler = () => {
    navigate("/home", { state: { elevatedCard: layoutId } });
  };

  const addCompletedTaskHandler = async (taskId: string) => {
    try {
      const updatedUser = localUserProfileInteractor.addCompletedTask(taskId) as UserProfile;
      queryClient.setQueryData(
        ["to-do-list"],
        (prevData: Data | undefined) => ({
          ...prevData!,
          completedPercentage: updatedUser.toDoListPercentage,
          userCompletedTaskIds: prevData!.userCompletedTaskIds.concat(taskId),
        })
      );
    } catch (error) {
      throw error as CustomError;
    }
  };

  const removeCompletedTaskHandler = (taskId: string) => {
    try {
      const updatedUser = localUserProfileInteractor.removeCompletedTask(taskId) as UserProfile;
      queryClient.setQueryData(
        ["to-do-list"],
        (prevData: Data | undefined) => ({
          ...prevData!,
          completedPercentage: updatedUser.toDoListPercentage,
          userCompletedTaskIds: prevData!.userCompletedTaskIds.filter(id => id !== taskId),
        })
      );
    } catch (error) {
      throw error as CustomError;
    }
  };

  const completeSectionHandler = () => {
    try {
      const updatedUser = localUserProfileInteractor.setToDoListCompleted() as UserProfile;
      queryClient.setQueryData(["to-do-list"], (prev: Data | undefined) => ({
        ...prev!,
        completedPercentage: updatedUser.toDoListPercentage,
        completed: updatedUser.toDoListCompleted,
        uncompletedSections: mapUserProfileToUncompletedSections(updatedUser)
      }));
    } catch (error) {
      throw error as CustomError;
    }
  }

  return {
    data: data ?? undefined,
    title,
    subtitle,
    layoutId,
    titleLayoutId,
    progressBarLayoutId,
    color,
    isLoading,
    isError,
    error,
    onBackHome: backHomeHandler,
    onAddCompleteTask: addCompletedTaskHandler,
    onRemoveCompleteTask: removeCompletedTaskHandler,
    onFinish: completeSectionHandler
  };
}
